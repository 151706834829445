import React from 'react';
import { Button, Input, Radio, Tooltip, Divider } from 'antd';
import { SearchOutlined, FilterOutlined, SortAscendingOutlined } from '@ant-design/icons';
import './TableControls.css';

function TableControls({ onSearch, onFilter, onSort, onViewChange, currentView, hasKanbanStatus }) {
  return (
    <div className="table-controls">
      <div className="view-controls">
        <Radio.Group value={currentView} onChange={(e) => onViewChange(e.target.value)}>
          <Radio.Button value="table">Table</Radio.Button>
          <Tooltip title={hasKanbanStatus ? '' : 'Specify a status column as the Kanban status to unlock the Kanban view'}>
            <Radio.Button value="kanban" disabled={!hasKanbanStatus}>Kanban</Radio.Button>
          </Tooltip>
        </Radio.Group>
      </div>
      <Divider className="view-divider" />
      <div className="search-filter-sort">
        <div className="left-controls">
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onChange={(e) => onSearch(e.target.value)}
            className="search-input"
          />
          <Button 
            icon={<FilterOutlined />} 
            onClick={onFilter}
            className="table-control-button"
          >
            Filter
          </Button>
          <Button 
            icon={<SortAscendingOutlined />} 
            onClick={onSort}
            className="table-control-button"
          >
            Sort
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TableControls;