import { message } from 'antd';
import { createContext, useContext, useCallback } from 'react';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = useCallback((type, content) => {
    if (messageApi[type] && typeof messageApi[type] === 'function') {
      messageApi[type](content);
    } else {
      console.warn(`Invalid message type: ${type}. Falling back to 'info'.`);
      messageApi.info(content);
    }
  }, [messageApi]);

  return (
    <MessageContext.Provider value={showMessage}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);