import React, { useState, useEffect, useCallback } from 'react';
import axios from '../api/axiosConfig';
import { io } from 'socket.io-client';
import DataTable from './DataTable';
import { Spin } from 'antd';
import './Content.css';
import dayjs from 'dayjs'; // Add this import

function Content({ title }) {
  const [boardData, setBoardData] = useState({ columns: [], data: [], column_widths: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [socket, setSocket] = useState(null);

  const fetchBoardData = useCallback(async () => {
    try {
      const response = await axios.get(`/table/${title.toLowerCase()}/`);
      if (response.data && response.data.columns) {
        // Convert date strings to dayjs objects if needed
        const processedData = response.data.data.map(item => ({
          ...item,
          'Work Date': item['Work Date'] ? dayjs(item['Work Date']) : null,
          'Due Date': item['Due Date'] ? dayjs(item['Due Date']) : null,
        }));
        setBoardData({
          ...response.data,
          data: processedData,
        });
      } else {
        console.error('Invalid board data received:', response.data);
        setError('Invalid board data received');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching board data:', error);
      if (error.response && error.response.status === 404) {
        await createNewBoard();
      } else {
        setError(`Failed to fetch board data: ${error.response?.data?.details || error.message}`);
        setIsLoading(false);
      }
    }
  }, [title]);

  const createNewBoard = useCallback(async () => {
    try {
      const response = await axios.post('/table/', {
        name: title.toLowerCase(),
        type: 'default'
      });
      if (response.data && response.data.columns) {
        setBoardData({
          columns: response.data.columns,
          data: [],
          column_widths: {}
        });
      } else {
        console.error('Invalid response when creating new board:', response.data);
        setError('Failed to create new board');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error creating new board:', error);
      setError(`Failed to create new board: ${error.response?.data?.details || error.message}`);
      setIsLoading(false);
    }
  }, [title]);

  const onUpdate = useCallback(async (itemId, updatedData, errorCallback) => {
    try {
      await axios.patch(`/table/${title.toLowerCase()}/items/${itemId}`, updatedData);
      await fetchBoardData();
    } catch (error) {
      console.error('Error updating item:', error);
      if (errorCallback) {
        errorCallback(error);
      }
    }
  }, [title, fetchBoardData]);

  const handleColumnUpdate = useCallback((columnName, updatedColumn) => {
    setBoardData(prevData => ({
      ...prevData,
      columns: prevData.columns.map(col => 
        col.name === columnName ? updatedColumn : col
      )
    }));
  }, []);

  useEffect(() => {
    const initializeBoard = async () => {
      try {
        await fetchBoardData();
      } catch (error) {
        console.error('Error initializing board:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeBoard();

    const newSocket = io();
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to server');
      newSocket.emit('join_room', title);
    });

    newSocket.on('update_board', () => {
      fetchBoardData();
    });

    return () => {
      newSocket.disconnect();
    };
  }, [title, fetchBoardData]);

  const handleColumnDelete = useCallback(async (columnName) => {
    try {
      await axios.delete(`/table/${title.toLowerCase()}/columns/${columnName}`);
      await fetchBoardData();
    } catch (error) {
      console.error('Error deleting column:', error);
    }
  }, [title, fetchBoardData]);

  const handleSearch = (searchTerm) => {
    // Implement search functionality
  };

  const handleFilter = () => {
    // Implement filter functionality
  };

  const handleSort = () => {
    // Implement sort functionality
  };

  if (isLoading) {
    return (
      <div className="content-loading">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div className="content-error">Error: {error}</div>;
  }

  return (
    <div className="content">
      <h1>{title}</h1>
      {boardData && boardData.columns && boardData.columns.length > 0 ? (
        <DataTable
          tableName={title.toLowerCase()}
          columns={boardData.columns}
          data={boardData.data || []}
          columnWidths={boardData.column_widths || {}}
          onUpdate={onUpdate}
          onColumnDelete={handleColumnDelete}
          onColumnUpdate={handleColumnUpdate}
          socket={socket}
          loading={isLoading}
        />
      ) : (
        <div>No columns defined for this table. Please add columns to start using the table.</div>
      )}
    </div>
  );
}

export default Content;