import React, { useState, useEffect } from 'react';
import axios from '../api/axiosConfig';
import { Button, Tabs, message, Tooltip, Checkbox } from 'antd';
import { ArrowLeftOutlined, CodeOutlined, CalendarOutlined, DollarOutlined, FieldTimeOutlined, MailOutlined, FormOutlined, FileAddOutlined, UserOutlined, CheckCircleOutlined, SafetyOutlined, LockOutlined, HomeOutlined, EnvironmentOutlined, PhoneOutlined, InboxOutlined } from '@ant-design/icons';
import './OnboardingDetails.css';
import { useNavigate } from 'react-router-dom';

const DealDetails = ({ onboardingId, onBack, onStageChange }) => {
  const [dealData, setDealData] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [currentStage, setCurrentStage] = useState(null);
  const [stages, setStages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dealResponse, stagesResponse] = await Promise.all([
          axios.get(`/onboarding/deal/${onboardingId}`),
          axios.get('/onboarding/stages')
        ]);
        setDealData(dealResponse.data);
        setTasks(dealResponse.data.tasks);
        setCurrentStage(dealResponse.data.stage);
        setStages(stagesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [onboardingId]);

  if (!dealData || stages.length === 0) return <div>Loading...</div>;

  const renderProductCard = (product) => (
    <div key={product.id} className="product-card">
      <h4>{product.name}</h4>
      <div className="product-details">
        <p><CodeOutlined className="icon" /> {product.code}</p>
        <p><CalendarOutlined className="icon" /> {product.billing_start_date}</p>
        <p><FieldTimeOutlined className="icon" /> {product.billing_frequency}</p>
      </div>
      <div className="product-pricing">
        <p><DollarOutlined className="icon" /> <span className="price">{product.item_price.toFixed(2)} DKK</span>{' '}per item</p>
        <p><strong>Quantity: </strong>{product.quantity}</p>
        <p className="total-price"><strong>Total: </strong><span className="price">{product.sum.toFixed(2)} DKK</span></p>
      </div>
    </div>
  );

  const getIconComponent = (iconName) => {
    const iconMap = {
      FileAddOutlined,
      CalendarOutlined,
      CheckCircleOutlined,
      UserOutlined,
      MailOutlined,
      SafetyOutlined,
      LockOutlined,
      // Add any other icons you need here
    };
    return iconMap[iconName] || null;
  };

  const renderProgressBar = () => {
    const currentStageIndex = stages.findIndex(stage => stage.id === currentStage);
  
    return (
      <div className="progress-bar-container">
        <div className="progress-bar">
          {stages.map((stage, index) => {
            const IconComponent = getIconComponent(stage.icon);
            return (
              <React.Fragment key={stage.id}>
                <Tooltip title={stage.name}>
                  <div 
                    className={`progress-stage ${index === currentStageIndex ? 'current' : index < currentStageIndex ? 'completed' : ''}`}
                    onClick={() => handleStageClick(stage.id)}
                  >
                    <div className="progress-stage-circle">
                      {IconComponent && <IconComponent className="progress-stage-icon" />}
                    </div>
                    {index === currentStageIndex && (
                      <div className="progress-stage-label">{stage.name}</div>
                    )}
                  </div>
                </Tooltip>
                {index < stages.length - 1 && (
                  <div className={`progress-line ${index < currentStageIndex ? 'completed' : ''}`}></div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const handleTaskAction = async (task) => {
    try {
      let result;
      switch (task.action) {
        case 'start_onboarding':
          result = await axios.post(`/onboarding/start/${onboardingId}`);
          break;
        case 'create_customer_in_economic':
          window.open(`/invoice-overview/${onboardingId}`, '_blank');
          return; // Exit the function early as we're opening a new tab
        default:
          throw new Error('Unknown task action');
      }
      
      if (result.data.success) {
        const updatedTasks = tasks.map(t => 
          t.id === task.id ? { ...t, completed: true } : t
        );
        setTasks(updatedTasks);
        message.success('Task completed successfully');
      }
    } catch (error) {
      console.error('Error performing task action:', error);
      message.error('Failed to complete task');
    }
  };

  const openForm = (formId) => {
    // Logic to open the form with conditional content based on deal data
    console.log(`Opening form ${formId} with deal data:`, dealData);
  };

  const renderTasks = () => (
    <div className="tasks-section">
      {tasks.map((task, index) => (
        <React.Fragment key={task.id}>
          <div className="task-item">
            {task.type === 'button' ? (
              <>
                <Button 
                  onClick={() => handleTaskAction(task)}
                  disabled={task.completed}
                  className={`task-button ${task.required ? 'required' : ''} ${task.completed ? 'completed' : ''}`}
                >
                  {task.task}
                </Button>
                {task.description && (
                  <p className="task-description">{task.description}</p>
                )}
              </>
            ) : task.type === 'form' ? (
              <>
                <div className="task-content">
                  <Checkbox
                    checked={task.completed}
                    onChange={() => handleTaskCompletion(task.id)}
                  >
                    {task.task}
                  </Checkbox>
                  <Button 
                    onClick={() => openForm(task.form_id)}
                    className={`form-button ${task.required ? 'required' : ''} ${task.completed ? 'completed' : ''}`}
                  >
                    Open Form
                  </Button>
                </div>
                {task.description && (
                  <p className="task-description">{task.description}</p>
                )}
              </>
            ) : (
              <>
                <Checkbox
                  checked={task.completed}
                  onChange={() => handleTaskCompletion(task.id)}
                >
                  {task.task}
                </Checkbox>
                {task.description && (
                  <p className="task-description">{task.description}</p>
                )}
              </>
            )}
          </div>
          {index < tasks.length - 1 && <div className="task-connector"></div>}
        </React.Fragment>
      ))}
      <div className="stage-actions">
        {canProgressToNextStage() && (
          <Button onClick={progressToNextStage} type="primary">
            Progress to Next Stage
          </Button>
        )}
        <Button onClick={skipToNextStage} type="default" className="skip-button">
          Skip to Next Stage
        </Button>
      </div>
    </div>
  );

  const canProgressToNextStage = () => {
    const currentStageConfig = stages.find(stage => stage.id === currentStage);
    if (!currentStageConfig || !currentStageConfig.can_progress) return false;

    if (currentStageConfig.progress_condition === 'all_required_tasks_completed') {
      return tasks.every(task => !task.required || task.completed);
    }

    // Add other conditions as needed
    return false;
  };

  const updateStageAndTasks = (newStage, newStageName, newTasks) => {
    setCurrentStage(newStage);
    setTasks(newTasks);
    setDealData(prevData => ({
      ...prevData,
      stage: newStage,
      stage_name: newStageName
    }));
    onStageChange(newStage, newStageName);
  };

  const progressToNextStage = async () => {
    if (!canProgressToNextStage()) return;
  
    try {
      const result = await axios.post(`/onboarding/progress-stage/${onboardingId}`);
      if (result.data.success) {
        updateStageAndTasks(result.data.new_stage, result.data.new_stage_name, result.data.new_tasks);
        message.success('Progressed to next stage successfully');
      }
    } catch (error) {
      console.error('Error progressing to next stage:', error);
      message.error('Failed to progress to next stage');
    }
  };

  const skipToNextStage = async () => {
    try {
      const result = await axios.post(`/onboarding/skip-stage/${onboardingId}`);
      if (result.data.success) {
        updateStageAndTasks(result.data.new_stage, result.data.new_stage_name, result.data.new_tasks);
        message.success('Skipped to next stage successfully');
      }
    } catch (error) {
      console.error('Error skipping to next stage:', error);
      message.error('Failed to skip to next stage');
    }
  };

  const handleStageClick = async (stageId) => {
    const currentStageIndex = stages.findIndex(stage => stage.id === currentStage);
    const clickedStageIndex = stages.findIndex(stage => stage.id === stageId);

    if (clickedStageIndex < currentStageIndex) {
      try {
        const result = await axios.post(`/onboarding/set-stage/${onboardingId}`, { stage: stageId });
        if (result.data.success) {
          updateStageAndTasks(result.data.new_stage, result.data.new_stage_name, result.data.new_tasks);
          message.success(`Moved back to ${result.data.new_stage_name} stage`);
        }
      } catch (error) {
        console.error('Error moving to previous stage:', error);
        message.error('Failed to move to previous stage');
      }
    }
  };

  const ContactSection = ({ contact, account }) => (
    <div className="contact-section">
      <h3><UserOutlined /> Contact Information</h3>
      <div className="contact-info">
        <p><UserOutlined /> {contact.name}</p>
        <p><HomeOutlined /> {account.address}</p>
        <p><EnvironmentOutlined /> {account.city}, {account.zipcode}</p>
        <p><MailOutlined /> {contact.email}</p>
        <p><PhoneOutlined /> {contact.phone}</p>
      </div>
    </div>
  );

  const EmailSection = ({ mainMailbox, corpayMailbox }) => (
    <div className="email-section">
      <h3><InboxOutlined /> Customer Emails</h3>
      <p>
        <strong>Bilags Mailbox: </strong>
        <span className={mainMailbox ? 'configured' : 'not-configured'}>
          {mainMailbox || 'Not configured'}
        </span>
      </p>
      <p>
        <strong>Corpay Mailbox: </strong>
        <span className={corpayMailbox ? 'configured' : 'not-configured'}>
          {corpayMailbox || 'Not configured'}
        </span>
      </p>
    </div>
  );

  const OnboardingTasksSection = ({ tasks }) => (
    <div className="onboarding-tasks-section">
      <h3><FormOutlined /> Onboarding Tasks</h3>
      <div className="onboarding-tasks-content">
        {tasks ? tasks : "Ingen"}
      </div>
    </div>
  );

  const handleTaskCompletion = async (taskId) => {
    const updatedTasks = tasks.map(task => 
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);

    try {
      await axios.put(`/onboarding/deal/${onboardingId}`, { tasks: updatedTasks });
      message.success('Task status updated');
    } catch (error) {
      console.error('Error updating task status:', error);
      message.error('Failed to update task status');
    }
  };

  const tabItems = [
    {
      key: '1',
      label: 'Case',
      children: (
        <div className="case-content">
          <div className="case-actions">
            <h2>Next Steps</h2>
            {renderTasks()}
          </div>
          <div className="case-info">
            <div className="info-header">
              <div className="info-item">
                <p><strong>Creation Date:</strong></p>
                <p>{new Date(dealData.created_at).toLocaleDateString()}</p>
              </div>
              <div className="info-item">
                <p><strong>Last Update:</strong></p>
                <p>{new Date(dealData.updated_at).toLocaleDateString()}</p>
              </div>
              <div className="info-item">
                <p><strong>Status:</strong></p>
                <p>
                  <span className={`status status-${dealData.stage}`}>
                    {dealData.stage_name || 'Unknown'}
                  </span>
                </p>
              </div>
            </div>
            <div className="info-details">
              <OnboardingTasksSection tasks={dealData.onboarding_tasks} />
              <ContactSection contact={dealData.deal.contact} account={dealData.deal.account} />
            </div>
            <div className="info-details">
              <EmailSection mainMailbox={dealData.deal.main_mailbox} corpayMailbox={dealData.deal.corpay_mailbox} />
            </div>
            <div className="product-section">
              <h3>Products</h3>
              <div className="product-grid">
                {dealData.deal.products.map(renderProductCard)}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Activity Log',
      children: <div>{/* Add activity log content here */}</div>,
    },
    {
      key: '3',
      label: 'Notes',
      children: <div>{/* Add notes content here */}</div>,
    },
  ];

  return (
    <div className="deal-details-container">
      <div className="deal-header">
        <Button icon={<ArrowLeftOutlined />} onClick={onBack}>Back to Cases</Button>
        <h1>Onboarding - {dealData.deal.name}</h1>
      </div>
      <div className="deal-details">
        {renderProgressBar()}
        <Tabs defaultActiveKey="1" items={tabItems} />
      </div>
    </div>
  );
};

export default DealDetails;