import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoNotificationsOutline, IoPersonAddOutline } from 'react-icons/io5';
import { BsSun, BsMoon } from 'react-icons/bs';
import { Dropdown, Modal, Table, Form, Input, Avatar } from 'antd';
import { FaUser, FaUsers, FaCog, FaSignOutAlt } from 'react-icons/fa';
import UserManagementModal from '../modals/UserManagement';
import UserSettings from '../modals/UserSettings';
import ActivityScreen from '../modals/ActivityScreen';
import axios from '../api/axiosConfig';
import axiosNoAuth from 'axios';
import './Header.css';

function Header({ theme, toggleTheme }) {
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const userMenuRef = useRef(null);
  const user = JSON.parse(localStorage.getItem('user')) || { name: 'User' };
  const initials = user.name ? user.name.charAt(0).toUpperCase() : 'U';
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  useEffect(() => {
    fetchCurrentUser();
    fetchOnlineUsers();
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get('/user/current');
      setCurrentUser(response.data);
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const fetchOnlineUsers = async () => {
    try {
      const response = await axios.get('/user');
      setOnlineUsers(response.data.filter(user => user.id !== currentUser?.id));
    } catch (error) {
      console.error('Error fetching online users:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await axiosNoAuth.post('/auth/logout');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    }
    setShowUserMenu(false);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get('/user/profile');
      setProfileData(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userMenuRef]);

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case '1':
        setShowProfileModal(true);
        fetchProfile();
        break;
      case '2':
        setShowTeamModal(true);
        fetchUsers();
        break;
      case '3':
        setShowSettingsModal(true);
        console.log('Settings modal opened');
        break;
      case '4':
        handleLogout();
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      key: '1',
      icon: <FaUser />,
      label: 'My Profile',
    },
    {
      key: '2',
      icon: <FaUsers />,
      label: 'Teams',
    },
    {
      key: '3',
      icon: <FaCog />,
      label: 'Settings',
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      icon: <FaSignOutAlt />,
      label: 'Logout',
    },
  ];

  const [showUserManagementModal, setShowUserManagementModal] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
    },
  ];

  const renderOnlineAvatars = () => {
    const maxVisibleAvatars = 5;
    const avatarsToShow = onlineUsers.slice(0, maxVisibleAvatars);
    const remainingCount = onlineUsers.length - maxVisibleAvatars;

    return (
      <div className="online-avatars" onClick={() => setIsActivityModalVisible(true)}>
        {avatarsToShow.map((user, index) => (
          <Avatar
            key={user.id}
            className="stacked-avatar"
            style={{
              backgroundColor: user.settings?.avatar_color || '#f56a00',
              zIndex: avatarsToShow.length - index,
            }}
          >
            {getInitials(user.name, user.surname)}
          </Avatar>
        ))}
        {remainingCount > 0 && (
          <Avatar className="stacked-avatar remaining-count" style={{ zIndex: 0 }}>
            +{remainingCount}
          </Avatar>
        )}
      </div>
    );
  };

  return (
    <header className="header">
      <div className="theme-toggle">
        <button className="header-icon-button" onClick={toggleTheme}>
          {theme === 'light' ? <BsMoon /> : <BsSun />}
        </button>
      </div>
      <div className="header-actions">
        {renderOnlineAvatars()}
        <button className="header-icon-button">
          <IoNotificationsOutline />
        </button>
        <button className="header-icon-button" onClick={() => setShowUserManagementModal(true)}>
          <IoPersonAddOutline />
        </button>
        <Dropdown
          menu={{ items: menuItems, onClick: handleMenuClick }}
          trigger={['click']}
          placement="bottomRight"
        >
          <div className="user-avatar-wrapper">
            <Avatar
              className="user-avatar"
              style={{ backgroundColor: currentUser?.settings?.avatar_color || '#f56a00' }}
            >
              {getInitials(currentUser?.name, currentUser?.surname)}
            </Avatar>
          </div>
        </Dropdown>
      </div>
      <UserManagementModal
        open={showUserManagementModal}
        onClose={() => setShowUserManagementModal(false)}
      />
      <UserSettings
        visible={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
      <Modal
        title="Team Members"
        open={showTeamModal}
        onCancel={() => setShowTeamModal(false)}
        footer={null}
      >
        <Table 
          dataSource={users} 
          columns={columns} 
          rowKey={(record) => record.id}
        />
      </Modal>
      <Modal
        title="My Profile"
        open={showProfileModal}
        onCancel={() => setShowProfileModal(false)}
        footer={null}
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input value={profileData.name} readOnly />
          </Form.Item>
          <Form.Item label="Surname">
            <Input value={profileData.surname} readOnly />
          </Form.Item>
          <Form.Item label="Email">
            <Input value={profileData.email} readOnly />
          </Form.Item>
          <Form.Item label="Permissions/Role">
            <Input value={profileData.permissions} readOnly />
          </Form.Item>
        </Form>
      </Modal>
      <ActivityScreen
        visible={isActivityModalVisible}
        onClose={() => setIsActivityModalVisible(false)}
        users={onlineUsers}
        currentUser={currentUser}
      />
    </header>
  );
}

export default Header;