import React, { useState, useEffect } from 'react';
import { Table, Spin, Button, Modal, message, Card, Descriptions, List, Typography, Checkbox, Tooltip, Alert, Select } from 'antd';
import { CalendarOutlined, DollarOutlined, NumberOutlined, ShoppingCartOutlined, FieldTimeOutlined, ExclamationCircleOutlined, WarningOutlined, TeamOutlined, UserOutlined, ShoppingOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import io from 'socket.io-client';
import './Deals.css';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const formatDataKey = (key) => {
  return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const formatDataValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (value instanceof Date) {
    return value.toLocaleString();
  }
  if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
    return new Date(value).toLocaleString();
  }
  return String(value);
};

const ProductItem = ({ product }) => (
  <List.Item className="product-item">
    <Tooltip title={`Product Name: ${product.name}`}>
      <Text strong className="product-name">{product.name}</Text>
    </Tooltip>
    <Tooltip title={`Product Code: ${product.code}`}>
      <Text><ShoppingCartOutlined /> Code: {product.code}</Text>
    </Tooltip>
    <Tooltip title={`Price: ${product.item_price}`}>
      <Text><DollarOutlined /> Price: {product.item_price}</Text>
    </Tooltip>
    <Tooltip title={`Quantity: ${product.quantity}`}>
      <Text><NumberOutlined /> Qty: {product.quantity}</Text>
    </Tooltip>
    <Tooltip title={`Sum: ${product.item_price * product.quantity}`}>
      <Text><DollarOutlined /> Sum: {product.item_price * product.quantity}</Text>
    </Tooltip>
    <Tooltip title={`Billing Start Date: ${product.billing_start_date || 'N/A'}`}>
      <Text><CalendarOutlined /> Start: {product.billing_start_date || 'N/A'}</Text>
    </Tooltip>
    <Tooltip title={`Billing Frequency: ${product.billing_frequency}`}>
      <Text><FieldTimeOutlined /> Freq: {product.billing_frequency}</Text>
    </Tooltip>
  </List.Item>
);

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [pendingDeals, setPendingDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [importing, setImporting] = useState(false);
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [productsModalVisible, setProductsModalVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [selectedAirCallContact, setSelectedAirCallContact] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDeals();
    fetchUsers();
    fetchPendingDeals();
    const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:5000');
    socket.on('pending_deal_added', () => {
      fetchPendingDeals();
    });
    socket.on('pending_deal_imported', () => {
      fetchDeals();
      fetchPendingDeals();
    });
    return () => socket.disconnect();
  }, []);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals');
      setDeals(response.data || []);
    } catch (error) {
      console.error('Error fetching deals:', error);
      setDeals([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingDeals = async () => {
    try {
      const response = await axios.get('/account/pending-deals');
      setPendingDeals(response.data || []);
    } catch (error) {
      console.error('Error fetching pending deals:', error);
      setPendingDeals([]);
    }
  };

  const handleImportDeal = async (dealId) => {
    setImporting(true);
    try {
      const response = await axios.post(`/account/import-deal/${dealId}`);
      const importedDeal = response.data;
      message.success('Deal imported successfully');
      fetchDeals();
      fetchPendingDeals();
      
      // Update the deals state with the new imported deal
      setDeals(prevDeals => [...prevDeals, importedDeal]);
    } catch (error) {
      console.error('Error importing deal:', error);
      message.error('Failed to import deal');
    } finally {
      setImporting(false);
    }
  };

  const handleImportAllDeals = async () => {
    setImporting(true);
    try {
      await axios.post('/account/import-all-deals');
      message.success('All deals imported successfully');
      setImportModalVisible(false);
      fetchDeals();
      fetchPendingDeals();
    } catch (error) {
      console.error('Error importing all deals:', error);
      message.error('Failed to import all deals');
    } finally {
      setImporting(false);
    }
  };

  const handleDealSelection = (dealId) => {
    setSelectedDeals(prevSelected => 
      prevSelected.includes(dealId)
        ? prevSelected.filter(id => id !== dealId)
        : [...prevSelected, dealId]
    );
  };

  const handleImportSelected = async () => {
    setImporting(true);
    try {
      await Promise.all(selectedDeals.map(dealId => axios.post(`/account/import-deal/${dealId}`)));
      message.success('Selected deals imported successfully');
      fetchDeals();
      fetchPendingDeals();
      setSelectedDeals([]);
    } catch (error) {
      console.error('Error importing selected deals:', error);
      message.error('Failed to import selected deals');
    } finally {
      setImporting(false);
    }
  };

  const showDealDetails = (deal) => {
    setSelectedDeal(deal);
    setSelectedAirCallContact(deal.recommendations?.aircall_contact || null);
    setDetailModalVisible(true);
  };

  const showAccountDetails = async (account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`);
      setSelectedAccount(response.data);
      setAccountModalVisible(true);
    } catch (error) {
      console.error('Error fetching account details:', error);
      message.error('Failed to fetch account details');
    }
  };

  const showContactDetails = (contact) => {
    setSelectedContact(contact);
    setContactModalVisible(true);
  };

  const showProductsDetails = (products) => {
    setSelectedProducts(products);
    setProductsModalVisible(true);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUserOptions(response.data.map(user => ({ value: user.id, label: user.name + ' ' + user.surname })));
    } catch (error) {
      console.error('Error fetching users:', error);
      setUserOptions([]);
    }
  };

  const handleAirCallContactChange = (value) => {
    setSelectedAirCallContact(value);
  };

  const excludedDataKeys = ['status', 'title', 'value', 'currency', 'products'];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Value',
      dataIndex: ['data', 'value'],
      key: 'value',
      render: (value, record) => `${value} ${record.data.currency}`,
      sorter: (a, b) => a.data.value - b.data.value,
    },
    {
      title: 'Status',
      dataIndex: ['data', 'status'],
      key: 'status',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString(),
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: 'Account',
      dataIndex: ['account', 'company_name'],
      key: 'account',
      render: (text, record) => (
        <Button type="link" onClick={() => showAccountDetails(record.account)}>
          {text && <><TeamOutlined /> {text}</>}
        </Button>
      ),
    },
    {
      title: 'Contact',
      dataIndex: ['contact', 'name'],
      key: 'contact',
      render: (text, record) => (
        <Button type="link" onClick={() => showContactDetails(record.contact)}>
          {text && <><UserOutlined /> {text}</>}
        </Button>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'products',
      key: 'products',
      render: (products) => (
        <Button type="link" onClick={() => showProductsDetails(products)}>
          {products.length > 0 && (
            <><ShoppingOutlined /> {products.length} product(s)</>
          )}
        </Button>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Zipcode',
      dataIndex: 'zipcode',
      key: 'zipcode',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    ...Object.keys(deals[0]?.data || {})
      .filter(key => !excludedDataKeys.includes(key))
      .map(key => ({
        title: formatDataKey(key),
        dataIndex: ['data', key],
        key: key,
        render: (value) => formatDataValue(value),
      })),
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  // Add this check
  if (!Array.isArray(deals)) {
    console.error('Deals is not an array:', deals);
    return <div>Error: Unable to load deals</div>;
  }

  return (
    <div className="deals-container">
      <h1>Deals</h1>
      {pendingDeals.length > 0 && (
        <Card className="pending-deals-card">
          <Button
            type="primary"
            onClick={() => setImportModalVisible(true)}
            className="import-deals-button"
          >
            Import Pending Deals ({pendingDeals.length})
          </Button>
        </Card>
      )}
      <Table
        columns={columns}
        dataSource={deals}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        className="deals-table nowrap-table" // Add this class
      />
      <Modal
        title="Import New Deals"
        open={importModalVisible}
        onCancel={() => setImportModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setImportModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="importAll"
            type="primary"
            onClick={handleImportAllDeals}
            loading={importing}
          >
            Import All
          </Button>,
          selectedDeals.length > 0 && (
            <Button
              key="importSelected"
              type="primary"
              onClick={handleImportSelected}
              loading={importing}
              className="import-selected-button"
            >
              Import Selected ({selectedDeals.length})
            </Button>
          ),
        ]}
        className="import-deals-modal"
      >
        {Array.isArray(pendingDeals) && pendingDeals.map(deal => (
          <Card 
            key={deal.id} 
            className={`pending-deal-card ${selectedDeals.includes(deal.id) ? 'selected' : ''}`}
            extra={
              <Checkbox
                checked={selectedDeals.includes(deal.id)}
                onChange={() => handleDealSelection(deal.id)}
              />
            }
          >
            <div className="pending-deal-header">
              <h3>{deal.data.title}</h3>
              {deal.recommendations && (
                <>
                  {Object.keys(deal.recommendations.recommendations).length > 0 && (
                    <Tooltip title="Automatic changes applied to pending deal">
                      <ExclamationCircleOutlined className="recommendation-icon" />
                    </Tooltip>
                  )}
                  {Object.keys(deal.recommendations.alerts).length > 0 && (
                    <Tooltip title="Alerts on pending deal data">
                      <WarningOutlined className="alert-icon" />
                    </Tooltip>
                  )}
                </>
              )}
              {(!deal.address || !deal.zipcode || !deal.city) && (
                <Tooltip title="Missing address data">
                  <WarningOutlined className="missing-data-icon" />
                </Tooltip>
              )}
            </div>
            <p>Organization: {deal.name}</p>
            <p>CVR: {deal.cvr}</p>
            <p>Value: {deal.data.value} {deal.data.currency}</p>
            <Button
              type="primary"
              onClick={() => handleImportDeal(deal.id)}
              loading={importing}
            >
              Import
            </Button>
            <Button onClick={() => showDealDetails(deal)}>View Details</Button>
          </Card>
        ))}
      </Modal>
      <Modal
        title="Deal Details"
        open={detailModalVisible}
        onCancel={() => setDetailModalVisible(false)}
        footer={null}
        width={1300}
        className="deal-details-modal"
      >
        {selectedDeal && (
          <>
            <Descriptions bordered column={1} labelStyle={{ fontWeight: 'bold', width: '120px' }}>
              <Descriptions.Item label="Name">{selectedDeal.name}</Descriptions.Item>
              <Descriptions.Item label="CVR">{selectedDeal.cvr}</Descriptions.Item>
              <Descriptions.Item label="Phone">{selectedDeal.phone}</Descriptions.Item>
              <Descriptions.Item label="Email">{selectedDeal.email}</Descriptions.Item>
              <Descriptions.Item label="Created At">{new Date(selectedDeal.created_at).toLocaleString()}</Descriptions.Item>
              <Descriptions.Item label="Address">
                <div className="deal-detail-item">
                  <div className="deal-detail-value">{selectedDeal.address}</div>
                  <div className="deal-detail-badges">
                    {selectedDeal.address ? (
                      <Alert
                        message="Fetched from CVR API"
                        type="info"
                        showIcon
                        className="alert info-alert"
                      />
                    ) : (
                      <Alert
                        message="No Address Found"
                        type="warning"
                        showIcon
                        className="alert warning-alert"
                      />
                    )}
                  </div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Zipcode">
                <div className="deal-detail-item">
                  <div className="deal-detail-value">{selectedDeal.zipcode}</div>
                  <div className="deal-detail-badges">
                    {selectedDeal.zipcode ? (
                      <Alert
                        message="Fetched from CVR API"
                        type="info"
                        showIcon
                        className="alert info-alert"
                      />
                    ) : (
                      <Alert
                        message="No Zipcode Found"
                        type="warning"
                        showIcon
                        className="alert warning-alert"
                      />
                    )}
                  </div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="City">
                <div className="deal-detail-item">
                  <div className="deal-detail-value">{selectedDeal.city}</div>
                  <div className="deal-detail-badges">
                    {selectedDeal.city ? (
                      <Alert
                        message="Fetched from CVR API"
                        type="info"
                        showIcon
                        className="alert info-alert"
                      />
                    ) : (
                      <Alert
                        message="No City Found"
                        type="warning"
                        showIcon
                        className="alert warning-alert"
                      />
                    )}
                  </div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Description">
                <div className="deal-detail-item">
                  <div className="deal-detail-value">{selectedDeal.description}</div>
                  <div className="deal-detail-badges">
                    {selectedDeal.description ? (
                      <Alert
                        message="Fetched from CVR API"
                        type="info"
                        showIcon
                        className="alert info-alert"
                      />
                    ) : (
                      <Alert
                        message="No Description Found"
                        type="warning"
                        showIcon
                        className="alert warning-alert"
                      />
                    )}
                  </div>
                </div>
              </Descriptions.Item>
              {Object.entries(selectedDeal.data)
                .filter(([key]) => !excludedDataKeys.includes(key))
                .map(([key, value]) => (
                  <Descriptions.Item key={key} label={formatDataKey(key)}>
                    <div className="deal-detail-item">
                      <div className="deal-detail-value">{formatDataValue(value)}</div>
                      <div className="deal-detail-recommendations">
                        {selectedDeal.recommendations?.recommendations[key] && (
                          <Alert
                            message={`Automatic correction: ${selectedDeal.recommendations.recommendations[key]}`}
                            type="info"
                            showIcon
                            className="alert recommendation-alert"
                          />
                        )}
                      </div>
                      <div className="deal-detail-alerts">
                        {selectedDeal.recommendations?.alerts[key] && (
                          <Alert
                            message={`Alert: ${selectedDeal.recommendations.alerts[key]}`}
                            type="error"
                            showIcon
                            className="alert error-alert"
                          />
                        )}
                      </div>
                    </div>
                  </Descriptions.Item>
                ))}
              <Descriptions.Item label="Products">
                <List
                  dataSource={selectedDeal.data.products || []}
                  renderItem={(product) => <ProductItem product={product} />}
                />
              </Descriptions.Item>
            </Descriptions>
            <div>
              <h4>AirCall Contact:</h4>
              <Select 
                value={selectedAirCallContact}
                onChange={handleAirCallContactChange}
                options={userOptions}
                className="aircall-contact-select"
              />
            </div>
          </>
        )}
      </Modal>
      <Modal
        title="Account Details"
        open={accountModalVisible}
        onCancel={() => setAccountModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setAccountModalVisible(false)}>
            Close
          </Button>,
          <Button key="view" type="primary" onClick={() => navigate(`/accounts/${selectedAccount.id}`)}>
            View Full Details
          </Button>,
        ]}
      >
        {selectedAccount && (
          <div>
            <p><strong>Company Name:</strong> {selectedAccount.company_name}</p>
            <p><strong>CVR:</strong> {selectedAccount.cvr || 'N/A'}</p>
            <p><strong>Kundenummer:</strong> {selectedAccount.kundenummer || 'N/A'}</p>
            <p><strong>Address:</strong> {selectedAccount.address || 'N/A'}</p>
            <p><strong>Zipcode:</strong> {selectedAccount.zipcode || 'N/A'}</p>
            <p><strong>City:</strong> {selectedAccount.city || 'N/A'}</p>
          </div>
        )}
      </Modal>

      <Modal
        title="Contact Details"
        open={contactModalVisible}
        onCancel={() => setContactModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setContactModalVisible(false)}>
            Close
          </Button>,
          <Button key="view" type="primary" onClick={() => navigate(`/contacts/${selectedContact.id}`)}>
            View Full Details
          </Button>,
        ]}
      >
        {selectedContact && (
          <div>
            <p><strong>Name:</strong> {selectedContact.name}</p>
            <p><strong>Email:</strong> {selectedContact.email}</p>
            <p><strong>Phone:</strong> {selectedContact.phone}</p>
          </div>
        )}
      </Modal>

      <Modal
        title="Products Details"
        open={productsModalVisible}
        onCancel={() => setProductsModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setProductsModalVisible(false)}>
            Close
          </Button>,
          <Button key="view" type="primary" onClick={() => navigate('/products')}>
            View All Products
          </Button>,
        ]}
        width={1000}
      >
        <List
          dataSource={selectedProducts}
          renderItem={(product) => <ProductItem product={product} />}
        />
      </Modal>
    </div>
  );
};

export default Deals;