import React from 'react';
import axios from '../api/axiosConfig';
import { Form, Input, Select, Button, Switch, message } from 'antd';

const { Option } = Select;

function ColumnSettings({ column, onClose, onUpdate, onDelete, boardName, isDefaultColumn }) {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    if (isDefaultColumn && values.name !== column.name) {
      message.error('Default column names cannot be changed.');
      return;
    }

    const updatedColumn = { ...column, ...values };
    if (values.isKanbanStatus) {
      try {
        const otherColumns = await axios.get(`/table/${boardName.toLowerCase()}/columns`);
        for (const col of otherColumns.data) {
          if (col.name !== column.name) {
            await axios.patch(`/table/${boardName.toLowerCase()}/columns/${col.name}`, { isKanbanStatus: false });
          }
        }
      } catch (error) {
        console.error('Error updating other columns:', error);
      }
    }
    await onUpdate(column.name, updatedColumn);
    onClose();
  };

  const handleDelete = async () => {
    if (await onDelete(column.name)) {
      onClose();
    }
  };

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={column}>
      <Form.Item name="name" label="Column Name">
        <Input disabled={isDefaultColumn} />
      </Form.Item>
      <Form.Item name="type" label="Column Type">
        <Select disabled={isDefaultColumn}>
          <Option value="Text">Text</Option>
          <Option value="Number">Number</Option>
          <Option value="Date">Date</Option>
          <Option value="Dropdown">Dropdown</Option>
          <Option value="Checkbox">Checkbox</Option>
          <Option value="Files">Files</Option>
          <Option value="Label">Label</Option>
          <Option value="Status">Status</Option>
        </Select>
      </Form.Item>
      {column.type === 'Status' && (
        <Form.Item name="isKanbanStatus" valuePropName="checked">
          <Switch checkedChildren="Kanban Status" unCheckedChildren="Not Kanban Status" />
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
        {!isDefaultColumn && (
          <Button type="danger" onClick={handleDelete} style={{ marginLeft: '10px' }}>
            Delete Column
          </Button>
        )}
      </Form.Item>
    </Form>
  );
}

export default ColumnSettings;