import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, message, Select } from 'antd';
import { UserOutlined, LockOutlined, EnvironmentOutlined } from '@ant-design/icons';
import axiosPublic from '../api/axiosPublic';
import logo from '../assets/logos/logo.png';
import './CompleteRegistration.css';

const { Title } = Typography;
const { Option } = Select;

function CompleteRegistration() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    // Fetch countries list
    axiosPublic.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const sortedCountries = response.data
          .map(country => country.name.common)
          .sort((a, b) => a.localeCompare(b));
        setCountries(sortedCountries);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axiosPublic.post('/auth/complete-registration', values, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.user && response.data.user.is_registered) {
        message.success('Registration completed successfully. Please log in with your new credentials.');
        // Clear user data from local storage
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        // Navigate to login page after a short delay
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        throw new Error('Registration failed');
      }
    } catch (error) {
      message.error('Failed to complete registration. Please try again.');
      console.error('Registration error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="complete-registration-container">
      <div className="complete-registration-card">
        <img src={logo} alt="Digi-tal ERP Logo" className="logo" />
        <Title level={2} className="registration-title">
          <span className="registration-title-text">Complete Your Profile</span>
        </Title>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="surname"
            rules={[{ required: true, message: 'Please input your surname!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Surname" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="city"
            rules={[{ required: true, message: 'Please input your city!' }]}
          >
            <Input prefix={<EnvironmentOutlined />} placeholder="City" />
          </Form.Item>
          <Form.Item
            name="country"
            rules={[{ required: true, message: 'Please select your country!' }]}
          >
            <Select
              showSearch
              placeholder="Select your country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map(country => (
                <Option key={country} value={country}>{country}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit-button" loading={loading}>
              Complete Registration
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CompleteRegistration;