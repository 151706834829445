import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { Card, Typography, Button, Avatar, Tooltip } from 'antd';
import Globe from 'globe.gl';
import moment from 'moment-timezone';
import axios from '../api/axiosConfig';
import './Home.css';
import { WiDaySunny, WiCloudy, WiRain, WiStrongWind, WiSnow, WiThunderstorm, WiDust } from 'react-icons/wi';
import ReactDOMServer from 'react-dom/server';

const { Title, Paragraph } = Typography;

function Home() {
  const globeEl = useRef();
  const globeInstanceRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(moment());
  const [updateType, setUpdateType] = useState('morning');
  const [isRotating, setIsRotating] = useState(true);
  const [users, setUsers] = useState([]);
  const [userColors, setUserColors] = useState({});
  const [weatherData, setWeatherData] = useState({});

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      //console.log('Fetched users:', response.data);
      setUsers(response.data);
      const colors = {};
      response.data.forEach(user => {
        colors[user.id] = user.settings?.avatar_color || '#f56a00';
      });
      setUserColors(colors);
      fetchWeatherData(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchWeatherData = async (users) => {
    try {
      const weatherResponse = await axios.get('/user/weather', { params: { user_ids: users.map(user => user.id) } });
      setWeatherData(weatherResponse.data);
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const now = moment();
      setCurrentTime(now);
      setUpdateType(now.hour() < 16 ? 'morning' : 'afternoon');
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useLayoutEffect(() => {
    if (!globeEl.current) return;

    const globe = Globe()
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
      .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
      .backgroundColor('rgba(0,0,0,0)') // Set background to transparent
      .width(globeEl.current.clientWidth)
      .height(globeEl.current.clientHeight);

    globe(globeEl.current);

    const resizeGlobe = () => {
      globe.width(globeEl.current.clientWidth);
      globe.height(globeEl.current.clientHeight);
    };

    window.addEventListener('resize', resizeGlobe);

    globe.controls().autoRotate = true;
    globe.controls().autoRotateSpeed = 0.5;
    globe.pointOfView({ lat: 20, lng: 45, altitude: 1.5 });

    globe.camera().zoom = 1;
    globe.camera().updateProjectionMatrix();
    globe.controls().minDistance = 101;
    globe.controls().maxDistance = 500;

    globeInstanceRef.current = globe;

    return () => {
      window.removeEventListener('resize', resizeGlobe);
      globe.controls().dispose();
      globe.renderer().dispose();
    };
  }, []);

  const getWeatherIcon = (iconCode) => {
    switch (iconCode) {
      case '01d':
      case '01n':
        return <WiDaySunny />;
      case '02d':
      case '02n':
      case '03d':
      case '03n':
      case '04d':
      case '04n':
        return <WiCloudy />;
      case '09d':
      case '09n':
      case '10d':
      case '10n':
        return <WiRain />;
      case '11d':
      case '11n':
        return <WiThunderstorm />;
      case '13d':
      case '13n':
        return <WiSnow />;
      case '50d':
      case '50n':
        return <WiDust />;
      case '23d':
      case '23n':
        return <WiStrongWind />;
      default:
        return <WiDaySunny />;
    }
  };

  const getGlobeTooltipContent = useCallback((user) => {
    return `
      <div class="globe-tooltip">
        <b>${user.name} ${user.surname}</b><br />
        ${user.location_info?.city}, ${user.location_info?.country}<br />
        Local time: ${moment().tz(user.location_info?.timezone || 'UTC').format('HH:mm')}<br />
        ${weatherData[user.id] ? `
          ${ReactDOMServer.renderToString(getWeatherIcon(weatherData[user.id].icon))} ${weatherData[user.id].temperature}°C ${weatherData[user.id].description}
        ` : 'Loading weather data...'}
      </div>
    `;
  }, [weatherData]);

  useEffect(() => {
    const globe = globeInstanceRef.current;
    if (!globe || !users.length) return;

    globe
      .pointsData(users)
      .pointLat(d => d.location_info?.latitude || 0)
      .pointLng(d => d.location_info?.longitude || 0)
      .pointColor(d => userColors[d.id] || '#FFFFFF')
      .pointAltitude(0.03)
      .pointRadius(0.5)
      .pointLabel(getGlobeTooltipContent);

    globe.controls().autoRotate = isRotating;
  }, [users, isRotating, userColors, getGlobeTooltipContent]);

  const getUserTooltipContent = useCallback((user) => {
    return (
      <div>
        <div>{getDisplayName(user)}</div>
        <div>{user.email}</div>
        <div>{getLocation(user.location_info)}</div>
        <div>{getLocalTime(user.location_info)}</div>
        {weatherData[user.id] ? (
          <div className="user-tooltip-weather">
            {getWeatherIcon(weatherData[user.id].icon)} {weatherData[user.id].temperature}°C {weatherData[user.id].description}
          </div>
        ) : (
          <div>Loading weather data...</div>
        )}
      </div>
    );
  }, [weatherData]);

  const toggleRotation = () => {
    setIsRotating(!isRotating);
  };

  const getLocalTime = (locationInfo) => {
    if (!locationInfo || !locationInfo.timezone) return 'Unknown';
    return moment().tz(locationInfo.timezone).format('HH:mm');
  };

  const getLocation = (locationInfo) => {
    if (!locationInfo) return 'Unknown';
    return `${locationInfo.city}, ${locationInfo.country}`;
  };

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  const getDisplayName = (user) => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || user.email || 'Unknown User';
  };

  const onlineUsers = users.filter(user => user.is_online);

  return (
    <div className="home-content">
      <Title level={2}>Good {updateType === 'morning' ? 'morning' : 'afternoon'}, Rasmus</Title>
      
      <div className="map-and-users-container">
        <Card title="Team Map" className="map-card">
          <div className="globe-container" ref={globeEl}></div>
          <Button onClick={toggleRotation} className="rotation-toggle-button">
            {isRotating ? 'Pause Rotation' : 'Resume Rotation'}
          </Button>
        </Card>

        <Card title="Online Users" className="online-users-card">
          <div className="online-users-list">
            {onlineUsers.map(user => (
              <Tooltip 
                key={user.id} 
                title={getUserTooltipContent(user)}
                placement="left"
              >
                <Avatar 
                  className="online-user-avatar"
                  style={{ backgroundColor: userColors[user.id] || '#f56a00' }}
                >
                  {getInitials(user.name, user.surname)}
                </Avatar>
              </Tooltip>
            ))}
          </div>
        </Card>
      </div>

      <Card title={updateType === 'morning' ? "Morning Update" : "Afternoon Update"} className="update-card">
        <Paragraph>
          {updateType === 'morning' 
            ? "Good morning! Here's your daily briefing: You have 3 overdue tasks, 2 high-priority items for today, and a new potential client inquiry. Let's make today productive!"
            : "Good afternoon! Here's your end-of-day summary: You've completed 7 tasks, made progress on the quarterly report, and scheduled 2 client meetings for tomorrow. Great job today!"}
        </Paragraph>
      </Card>
    </div>
  );
}

export default Home;