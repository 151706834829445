import React, { useState, useEffect } from 'react';
import './App.css';
import './AntDesignOverrides.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ConfigProvider, theme, App as AntApp } from 'antd';
import { MessageProvider } from './contexts/MessageContext';
import Login from './pages/Login';
import Boards from './pages/Boards';
import CompleteRegistration from './pages/CompleteRegistration';

import OverlayHub from './overlays/OverlayHub';

import MainMenu from './pages/MainMenu';
import DigiDashboard from './digi-tal-dashboard/Digi-Dashboard';
import Bot from './digi-tal-bot/Digi-Regnskabsbot';
import InvoiceOverview from './invoice/InvoiceOverview';

// New HOC for protected routes
const ProtectedRoute = ({ children, requiredPermission }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  console.log('ProtectedRoute - User:', user);
  console.log('ProtectedRoute - Required Permission:', requiredPermission);

  if (!user) {
    console.log('ProtectedRoute - No user found, redirecting to login');
    return <Navigate to="/login" replace />;
  }

  // Allow access to complete-registration for users who are not yet registered
  if (!user.is_registered && window.location.pathname === '/complete-registration') {
    return children;
  }

  if (requiredPermission && !hasRequiredPermission(user.permissions, requiredPermission)) {
    console.log('ProtectedRoute - Insufficient permissions, redirecting to main menu');
    return <Navigate to="/main-menu" replace />;
  }

  console.log('ProtectedRoute - Access granted');
  return children;
};

// Helper function to check if user has required permission
const hasRequiredPermission = (userPermissions, requiredPermission) => {
  const permissionLevels = {
    owner: ['owner'],
    admin: ['owner', 'admin'],
    user: ['owner', 'admin', 'user', 'accounting']
  };
  return permissionLevels[requiredPermission].some(perm => userPermissions.includes(perm));
};

function App() {
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('theme') || 'dark');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      //console.log('Stored user:', parsedUser);
      setUser(parsedUser);
    }
  }, []);

  useEffect(() => {
    document.body.setAttribute('data-theme', currentTheme);
  }, [currentTheme]);

  const toggleTheme = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setCurrentTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const { defaultAlgorithm, darkAlgorithm } = theme;

  return (
    <ConfigProvider
      theme={{
        algorithm: currentTheme === 'dark' ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: currentTheme === 'light' ? '#0070FF' : '#4299E1',
          colorBgContainer: currentTheme === 'light' ? '#FFFFFF' : '#1A202C',
          colorText: currentTheme === 'light' ? '#181818' : '#F7FAFC',
          colorBgTextHover: currentTheme === 'light' ? '#F7FAFC' : '#2D3748',
        },
        components: {
          Table: {
            colorBgContainer: currentTheme === 'light' ? '#FFFFFF' : '#1A202C',
            colorText: currentTheme === 'light' ? '#181818' : '#F7FAFC',
            colorTextHeading: currentTheme === 'light' ? '#181818' : '#F7FAFC',
            colorBgTextHover: currentTheme === 'light' ? '#F7FAFC' : '#2D3748',
          },
        },
      }}
    >
      <MessageProvider>
        <AntApp>
          <Router>
            <div className="App" data-theme={currentTheme}>
              <Routes>
                <Route path="/" element={<Login theme={currentTheme} setUser={setUser} />} />
                <Route path="/login" element={<Login theme={currentTheme} setUser={setUser} />} />
                <Route path="/complete-registration" element={<ProtectedRoute><CompleteRegistration /></ProtectedRoute>} />
                <Route 
                  path="/main-menu" 
                  element={
                    <ProtectedRoute requiredPermission="user">
                      <MainMenu theme={currentTheme} user={user} />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/digi-dashboard" 
                  element={
                    <ProtectedRoute requiredPermission="admin">
                      <DigiDashboard theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/bot" 
                  element={
                    <ProtectedRoute requiredPermission="user">
                      <Bot theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/invoice-overview" 
                  element={
                    <ProtectedRoute requiredPermission="user">
                      <InvoiceOverview theme={currentTheme} user={user} />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/invoice-overview/:onboardingId" 
                  element={
                    <ProtectedRoute requiredPermission="user">
                      <InvoiceOverview theme={currentTheme} user={user} />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/*" 
                  element={
                    <ProtectedRoute requiredPermission="user">
                      <Boards theme={currentTheme} toggleTheme={toggleTheme} user={user} />
                    </ProtectedRoute>
                  } 
                />
              </Routes>
              <OverlayHub />
            </div>
          </Router>
        </AntApp>
      </MessageProvider>
    </ConfigProvider>
  );
}

export default App;