import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../api/axiosConfig';
import './InvoiceOverview.css';
import logo from '../assets/logos/logo.png';

const InvoiceOverview = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [error, setError] = useState(null);
  const { onboardingId } = useParams();

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await axios.get(`/onboarding/invoice-data/${onboardingId}`);
        setInvoiceData(response.data);
      } catch (error) {
        console.error('Error fetching invoice data:', error);
      }
    };

    fetchInvoiceData();
  }, [onboardingId]);

  const handleMonthlyPayment = async () => {
    try {
      const response = await axios.post(`/onboarding/${onboardingId}/create-economic-customer`, {
        paymentType: 'monthly'
      });
      if (response.data.success) {
        alert('Customer created successfully in e-conomic!');
        // You might want to update the UI or redirect the user here
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('An error occurred while creating the customer in e-conomic.');
    }
  };

  if (!invoiceData) return <div>Loading...</div>;

  // Helper function to format numbers
  const formatNumber = (num) => {
    return parseFloat(num).toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',');
  };

  // Calculate yearly prices with 15% discount
  const calculateYearlyDiscount = (price) => price * 12 * 0.85;

  return (
    <div className="invoice-container">
      <div className="invoice-header">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="invoice-title">Digi-Tal Onboarding Invoice</h1>
      </div>
      <div className="invoice-content">
        <div className="client-info">
          <p><strong>Firma:</strong> {invoiceData.company_name}</p>
          <p><strong>CVR:</strong> {invoiceData.cvr}</p>
          <p><strong>Kontakt:</strong> {invoiceData.contact_name}</p>
        </div>
        <div className="payment-grid">
          <div className="payment-option">
            <h2>Månedlig betaling</h2>
            <table>
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Pris/md</th>
                  <th>Pris/år</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData.products.map((product, index) => (
                  <tr key={index}>
                    <td>{product.name}</td>
                    <td>kr. {formatNumber(product.monthly_price)}</td>
                    <td>kr. {formatNumber(product.yearly_price)}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>kr. {formatNumber(invoiceData.total_monthly)}</strong></td>
                  <td><strong>kr. {formatNumber(invoiceData.total_yearly)}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="payment-option">
            <h2>Årlig betaling <span className="discount">15% rabat</span></h2>
            <table>
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Pris/md</th>
                  <th>Pris/år</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData.products.map((product, index) => (
                  <tr key={index}>
                    <td>{product.name}</td>
                    <td>kr. {formatNumber(calculateYearlyDiscount(product.monthly_price) / 12)}</td>
                    <td>kr. {formatNumber(calculateYearlyDiscount(product.monthly_price))}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>kr. {formatNumber(invoiceData.total_yearly_discounted / 12)}</strong></td>
                  <td><strong>kr. {formatNumber(invoiceData.total_yearly_discounted)}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="total-option">
            <h2>Månedlig - Total</h2>
            <table>
              <tbody>
                <tr>
                  <td>Start:</td>
                  <td>{invoiceData.start_date}</td>
                </tr>
                <tr>
                  <td>Onboarding</td>
                  <td>kr. {formatNumber(invoiceData.onboarding_fee)}</td>
                </tr>
                <tr>
                  <td>Pris pr. måned</td>
                  <td>kr. {formatNumber(invoiceData.total_monthly)}</td>
                </tr>
                <tr>
                  <td>Til betaling nu</td>
                  <td>kr. {formatNumber(invoiceData.onboarding_fee)}</td>
                </tr>
              </tbody>
            </table>
            <p className="note">Alle priser er ekskl. moms</p>
            <button className="payment-button" onClick={handleMonthlyPayment}>Vælg månedlig betaling</button>
            {error && <div className="error-message">{error}</div>}
          </div>
          <div className="total-option">
            <h2>Årlig - Total</h2>
            <table>
              <tbody>
                <tr>
                  <td>Start:</td>
                  <td>{invoiceData.start_date}</td>
                </tr>
                <tr>
                  <td>Onboarding</td>
                  <td>kr. {formatNumber(invoiceData.onboarding_fee_discounted)}</td>
                </tr>
                <tr>
                  <td>Pris pr. år</td>
                  <td>kr. {formatNumber(invoiceData.total_yearly_discounted)}</td>
                </tr>
                <tr>
                  <td>Til betaling nu</td>
                  <td>kr. {formatNumber(invoiceData.onboarding_fee_discounted + invoiceData.total_yearly_discounted)}</td>
                </tr>
              </tbody>
            </table>
            <p className="note">Alle priser er ekskl. moms</p>
            <button className="payment-button">Vælg årlig betaling</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceOverview;