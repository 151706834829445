import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from '../api/axiosConfig';
import { Table, Button, Dropdown, Modal, message, Skeleton } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SettingOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import ColumnTypeComponents, { columnDefaults } from './ColumnTypeComponents';
import TableControls from './TableControls';
import KanbanView from './KanbanView';
import AddColumnModal from './AddColumnModal';
import ColumnSettings from '../settings/ColumnSettings';
import RowSettings from '../settings/RowSettings';
import TaskView from './TaskView';
import './DataTable.css';
import 'react-resizable/css/styles.css';
import { Resizable } from 'react-resizable';
import dayjs from 'dayjs';

function getAddButtonText(tableName) {
  const nameMap = {
    andreopgaver: 'Task',
    onboarding: 'Task',
    bogforing: 'Task',
    activities: 'Task',
    lon: 'Task',
    moms: 'Task',
    arsafslutning: 'Task',
    udvikling: 'Task'
  };
  return `Add ${nameMap[tableName] || tableName.slice(0, -1)}`;
}

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function DataTable({ tableName, columns, data, onUpdate, onColumnDelete, socket, loading }) {
  const [localData, setLocalData] = useState(data);
  const [currentView, setCurrentView] = useState('table');
  const [kanbanStatusColumn, setKanbanStatusColumn] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [columnWidths, setColumnWidths] = useState({});
  const [resizingColumn, setResizingColumn] = useState(null);
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [showColumnSettings, setShowColumnSettings] = useState(null);
  const [showColumnSettingsModal, setShowColumnSettingsModal] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [activeRowMenu, setActiveRowMenu] = useState(null);
  const rowActionsRef = useRef(null);
  const hoverTimeoutRef = useRef(null);
  const [showRowSettings, setShowRowSettings] = useState(null);
  const tableRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskViewVisible, setIsTaskViewVisible] = useState(false);

  useEffect(() => {
    setLocalData((prevData) => {
      const newData = [...data];
      const optimisticRows = prevData.filter((row) => row.id.toString().startsWith('temp-'));
      return [...newData, ...optimisticRows];
    });
  }, [data]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
    }
  };

  useEffect(() => {
    const kanbanColumn = columns.find(col => col.isKanbanStatus);
    setKanbanStatusColumn(kanbanColumn || null);
    console.log('Kanban status column:', kanbanColumn);
  }, [columns]);

  const handleDragEnd = useCallback((result) => {
    if (!result.destination) return;

    const items = Array.from(localData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLocalData(items);
    axios.post(`/table/${tableName}/reorder`, { items: items.map(item => item.id) });
  }, [localData, tableName]);

  const handleKanbanDragEnd = (result) => {
    if (!result.destination) return;
  
    const { source, destination, draggableId } = result;
  
    if (source.droppableId !== destination.droppableId) {
      const updatedData = localData.map(item => {
        if (item.id.toString() === draggableId) {
          const updatedItem = { ...item, [kanbanStatusColumn.name]: destination.droppableId };
          onUpdate(item.id, { [kanbanStatusColumn.name]: destination.droppableId }, (error) => {
            if (error) {
              // Revert the change if there's an error
              setLocalData(prevData => prevData.map(i => 
                i.id === item.id ? { ...i, [kanbanStatusColumn.name]: source.droppableId } : i
              ));
            }
          });
          return updatedItem;
        }
        return item;
      });
  
      setLocalData(updatedData);
    }
  };


  const handleResizeMouseDown = (e, column) => {
    e.preventDefault();
    setResizingColumn(column);
    document.addEventListener('mousemove', handleResizeMouseMove);
    document.addEventListener('mouseup', handleResizeMouseUp);
  };

  const handleResizeMouseMove = useCallback(
    (e) => {
      if (resizingColumn && tableRef.current) {
        const tableRect = tableRef.current.getBoundingClientRect();
        const newWidth = Math.max(100, e.clientX - tableRect.left - columnWidths[resizingColumn.name]);
        setColumnWidths((prevWidths) => ({
          ...prevWidths,
          [resizingColumn.name]: newWidth,
        }));
      }
    },
    [resizingColumn, columnWidths]
  );

  const handleResizeMouseUp = useCallback(() => {
    setResizingColumn(null);
    document.removeEventListener('mousemove', handleResizeMouseMove);
    document.removeEventListener('mouseup', handleResizeMouseUp);
    // Save column widths to backend
    axios.patch(`/table/${tableName}/column_widths`, columnWidths);
  }, [columnWidths, handleResizeMouseMove, tableName]);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleResizeMouseMove);
      document.removeEventListener('mouseup', handleResizeMouseUp);
    };
  }, [handleResizeMouseMove, handleResizeMouseUp]);

  const handleCellEdit = useCallback((rowId, columnName, value) => {
    setLocalData((prevData) =>
        prevData.map((row) =>
            row.id === rowId ? { ...row, [columnName]: value } : row
        )
    );
    // Immediately save the change to the backend
    handleCellBlur(rowId, columnName, value);
  }, []);

  // Save the value to the backend
  const handleCellBlur = useCallback(
    async (rowId, columnName, value) => {
      try {
        const response = await axios.patch(`/table/${tableName}/items/${rowId}`, {
          [columnName]: value,
        });
        console.log('Updated item:', response.data);
        setLocalData((prevData) =>
          prevData.map((row) =>
            row.id === rowId ? { ...row, ...response.data.data } : row
          )
        );
      } catch (error) {
        console.error('Error updating cell:', error);
      }
    },
    [tableName]
  );

  const handleDeleteRow = async (rowId) => {
    try {
      await axios.delete(`/table/${tableName}/rows/${rowId}`);
      setLocalData((prevData) => prevData.filter((row) => row.id !== rowId));
      message.success('Row deleted successfully');
    } catch (error) {
      console.error('Error deleting row:', error);
      message.error('Failed to delete row');
    }
  };

  const handleAddRow = async () => {
    if (!columns || columns.length === 0) {
      console.error('No columns defined for this table');
      return;
    }

    const newItem = {};
    columns.forEach((col) => {
      switch (col.name) {
        case 'Task':
          newItem[col.name] = '';
          break;
        case 'Assignee':
          newItem[col.name] = [];
          break;
        case 'Status':
          newItem[col.name] = 'Not Started';
          break;
        case 'Work Date':
        case 'Due Date':
          newItem[col.name] = dayjs().format('YYYY-MM-DD'); // Use dayjs here
          break;
        default:
          newItem[col.name] = columnDefaults[col.type.toLowerCase()] !== undefined ? columnDefaults[col.type.toLowerCase()] : '';
      }
    });
    const optimisticId = `temp-${Date.now()}`;
    const optimisticRow = { id: optimisticId, ...newItem };
    
    setLocalData(prevData => [...prevData, optimisticRow]);
    setEditingCell(`${optimisticId}-${columns[0].name}`);

    try {
      const response = await axios.post(`/table/${tableName}/items`, newItem);
      // Update the optimistic row with the real ID from the server
      setLocalData(prevData => prevData.map(item => 
        item.id === optimisticId ? { ...item, id: response.data.id } : item
      ));
      // Focus the first input field of the new row
      setTimeout(() => {
        const newRowElement = document.querySelector(`[data-row-key="${response.data.id}"]`);
        if (newRowElement) {
          const firstInput = newRowElement.querySelector('input, textarea, select');
          if (firstInput) {
            firstInput.focus();
          }
        }
      }, 0);
    } catch (error) {
      console.error('Error adding new row:', error);
      // Remove the optimistic row only if there's an error
      setLocalData(prevData => prevData.filter(item => item.id !== optimisticId));
    } finally {
      setEditingCell(null);
    }
  };

  const handleAddColumn = async (columnName, columnType) => {
    try {
      const response = await axios.post(`/table/${tableName}/columns`, { name: columnName, type: columnType });
      const newColumn = response.data.column;
      
      // Update local data with default values for the new column
      setLocalData(prevData => prevData.map(row => ({
        ...row,
        [columnName]: columnDefaults[columnType.toLowerCase()] || ''
      })));

      onUpdate();
      setShowAddColumnModal(false);
    } catch (error) {
      console.error('Error adding new column:', error);
    }
  };

  const handleColumnSettingsClick = (column) => {
    setShowColumnSettings(column);
    setShowColumnSettingsModal(true);
  };
  
  const handleUpdateColumn = useCallback(async (columnName, updateData) => {
    try {
      await axios.patch(`/table/${tableName}/columns/${columnName}`, updateData);
      onUpdate(); // This will trigger a re-fetch of the board data
    } catch (error) {
      console.error('Error updating column:', error);
    }
  }, [tableName, onUpdate]);

  const handleColumnDelete = useCallback(async (columnName) => {
    try {
      await axios.delete(`/table/${tableName}/columns/${columnName}`);
      message.success('Column deleted successfully');
      onUpdate(); // This will trigger a re-fetch of the board data
    } catch (error) {
      console.error('Error deleting column:', error);
      message.error('Failed to delete column');
    }
  }, [tableName, onUpdate]);

  const handleOpenTask = (task) => {
    setSelectedTask(task);
    setIsTaskViewVisible(true);
  };

  const handleCloseTask = (nextTask = null) => {
    if (nextTask) {
      setSelectedTask(nextTask);
    } else {
      setIsTaskViewVisible(false);
      setSelectedTask(null);
    }
  };

  const tableColumns = [
    ...(columns || []).map((column, index) => ({
      title: (
        <div 
          className="column-header"
          onMouseEnter={() => setHoveredColumn(column.name)}
          onMouseLeave={() => setHoveredColumn(null)}
        >
          <span>{column.name}</span>
          {hoveredColumn === column.name && (
            <Button
              icon={<SettingOutlined />}
              onClick={() => handleColumnSettingsClick(column)}
              className="column-settings-button"
            />
          )}
          <div
            className="column-resizer"
            onMouseDown={(e) => handleResizeMouseDown(e, column)}
          />
        </div>
      ),
      dataIndex: column.name,
      key: column.name,
      width: columnWidths[column.name],
      className: 'data-table-cell',
      render: (text, record) => {
        const Component = ColumnTypeComponents[column.type.toLowerCase()];
        if (!Component) {
          console.error(`No component found for column type: ${column.type}`);
          return null;
        }
        return (
          <div className={`${column.type.toLowerCase() === 'status' ? 'status-cell' : ''} ${index === 0 ? 'task-cell' : ''}`}>
            <Component
              value={text}
              onChange={(newValue) => handleCellEdit(record.id, column.name, newValue)}
              onBlur={(value) => handleCellBlur(record.id, column.name, value)}
              column={column}
              onUpdateColumn={handleUpdateColumn}
              users={users}
            />
            {index === 0 && (
              <Button
                className="open-task-button"
                icon={<EyeOutlined />}
                onClick={() => handleOpenTask(record)}
              >
                Open
              </Button>
            )}
          </div>
        );
      },
    })),
    {
      title: (
        <Button
          icon={<PlusOutlined />}
          onClick={() => setShowAddColumnModal(true)}
          className="add-column-button"
        />
      ),
      key: 'add-column',
      width: 50,
      render: () => null,
    },
  ];

  const handleRowAction = (action, rowId) => {
    switch (action) {
      case 'open':
        // Handle open task
        break;
      case 'openNewTab':
        // Handle open in new tab
        break;
      case 'copyLink':
        // Handle copy task link
        break;
      case 'moveTo':
        // Handle move to
        break;
      case 'duplicate':
        // Handle duplicate
        break;
      case 'createBelow':
        // Handle create new task below
        break;
      case 'archive':
        // Handle archive
        break;
      case 'delete':
        handleDeleteRow(rowId);
        break;
      default:
        break;
    }
    setActiveRowMenu(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (rowActionsRef.current && !rowActionsRef.current.contains(event.target)) {
        setActiveRowMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleRowHover = (index) => {
    clearTimeout(hoverTimeoutRef.current);
    setHoveredRowIndex(index);
  };

  const handleRowLeave = () => {
    clearTimeout(hoverTimeoutRef.current);
    hoverTimeoutRef.current = setTimeout(() => {
      if (!activeRowMenu) {
        setHoveredRowIndex(null);
      }
    }, 300); // 300ms delay before hiding the icon
  };
  
  const addRowButton = (
    <Button
      className="add-row-button"
      onClick={handleAddRow}
      disabled={editingCell !== null}
    >
      {getAddButtonText(tableName)}
    </Button>
  );

  const hasKanbanStatus = columns.some(col => col.type.toLowerCase() === 'status');

  if (loading) {
    return (
      <div className="skeleton-table">
        <Skeleton.Input style={{ width: '100%', marginBottom: '16px' }} active />
        <Skeleton active paragraph={{ rows: 10 }} />
      </div>
    );
  }

  return (
    <div className="data-table-container" ref={tableRef}>
      <TableControls
        onSearch={() => {}} // Implement search functionality
        onFilter={() => {}} // Implement filter functionality
        onSort={() => {}} // Implement sort functionality
        onViewChange={setCurrentView}
        currentView={currentView}
        hasKanbanStatus={!!kanbanStatusColumn}
      />
      {currentView === 'table' ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="table-wrapper">
                  <div className="row-actions-wrapper" ref={rowActionsRef}>
                    {localData.map((row, index) => {
                      const { menu, icon } = RowSettings({ rowId: row.id, onAction: handleRowAction });
                      return (
                        <div
                          key={row.id}
                          className={`row-action ${hoveredRowIndex === index ? 'visible' : ''}`}
                          style={{ top: `${(index + 1) * 53}px` }}
                          onMouseEnter={() => handleRowHover(index)}
                          onMouseLeave={handleRowLeave}
                        >
                          <Dropdown
                            menu={menu}
                            trigger={['click']}
                            open={activeRowMenu === row.id}
                            onOpenChange={(open) => setActiveRowMenu(open ? row.id : null)}
                          >
                            <Button
                              icon={icon}
                              className="row-action-button"
                            />
                          </Dropdown>
                        </div>
                      );
                    })}
                  </div>
                  <Table
                    columns={tableColumns}
                    dataSource={localData.map(item => ({...item, key: item.id}))}
                    rowKey="id"
                    pagination={false}
                    components={{
                      body: {
                        row: (props) => {
                          const { children, ...restProps } = props;
                          // Check if this is a placeholder row
                          if (restProps.className && restProps.className.includes('ant-table-placeholder')) {
                            return <tr {...restProps}>{children}</tr>;
                          }
                          const index = localData.findIndex(item => item && item.id === restProps['data-row-key']);
                          if (index === -1 || !restProps['data-row-key']) {
                            console.error('Invalid row data:', props);
                            return null; // or return a placeholder row
                          }
                          return (
                            <Draggable 
                              key={restProps['data-row-key']} 
                              draggableId={restProps['data-row-key'].toString()} 
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...restProps}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {children}
                                </tr>
                              )}
                            </Draggable>
                          );
                        },
                      },
                    }}
                    onRow={(record, index) => ({
                      index,
                      onMouseEnter: () => handleRowHover(index),
                      onMouseLeave: handleRowLeave,
                    })}
                    scroll={{ x: 'max-content' }}
                  />
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <KanbanView
          columns={columns}
          data={localData}
          kanbanStatusColumn={kanbanStatusColumn}
          onDragEnd={handleKanbanDragEnd}
          onUpdate={onUpdate}
          users={users}
          loading={loading}
        />
      )}
      {addRowButton}
      <Modal
        title="Column Settings"
        open={showColumnSettingsModal}
        onCancel={() => {
          setShowColumnSettingsModal(false);
          setShowColumnSettings(null);
        }}
        footer={null}
      >
        {showColumnSettings && (
          <ColumnSettings
            column={showColumnSettings}
            onClose={() => {
              setShowColumnSettingsModal(false);
              setShowColumnSettings(null);
            }}
            onUpdate={handleUpdateColumn}
            onDelete={handleColumnDelete}
            boardName={tableName.toLowerCase()}
            isDefaultColumn={['Task', 'Assignee', 'Status', 'Work Date', 'Due Date'].includes(showColumnSettings.name)}
          />
        )}
      </Modal>
      <Modal
        title="Row Settings"
        open={showRowSettings !== null}
        onCancel={() => setShowRowSettings(null)}
        footer={null}
      >
        {showRowSettings && (
          <RowSettings
            rowId={showRowSettings}
            onClose={() => setShowRowSettings(null)}
            onUpdate={onUpdate}
            onDelete={handleDeleteRow}
          />
        )}
      </Modal>
      {showAddColumnModal && (
        <AddColumnModal
          onAdd={handleAddColumn}
          onClose={() => setShowAddColumnModal(false)}
        />
      )}
      <Modal
        open={isTaskViewVisible}
        onCancel={() => handleCloseTask()}
        footer={null}
        width="80%"
        destroyOnClose
      >
        {selectedTask && (
          <TaskView
            task={selectedTask}
            columns={columns}
            onUpdate={onUpdate}
            onClose={handleCloseTask}
            users={users}
            allTasks={localData}
          />
        )}
      </Modal>
    </div>
  );
}

export default DataTable;