import React, { useEffect, useState } from 'react';
import { Form, Input, Select, InputNumber, Radio, Button, Typography, Checkbox, Space } from 'antd';
import { useParams } from 'react-router-dom';
import axios from '../../api/axiosConfig';
import './CustomerOnboardingForm.css';

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const CustomerOnboardingForm = () => {
  const [form] = Form.useForm();
  const { onboardingId } = useParams();
  const [dealData, setDealData] = useState(null);

  useEffect(() => {
    const fetchDealData = async () => {
      try {
        const response = await axios.get(`/onboarding/deal/${onboardingId}`);
        setDealData(response.data);
        // Pre-fill form fields based on dealData
        form.setFieldsValue({
          // Add pre-filled fields here
        });
      } catch (error) {
        console.error('Error fetching deal data:', error);
      }
    };

    fetchDealData();
  }, [onboardingId, form]);

  const onFinish = (values) => {
    console.log('Form values:', values);
    // Handle form submission
  };

  return (
    <div className="customer-onboarding-wrapper">
      <div className="customer-onboarding-form">
        <Title level={2}>Customer Onboarding Form</Title>
        <Paragraph className="form-intro">
          Præsenter dig selv og sæt rammerne for mødet. - Vigtigt at fortælle du ikke er revisor eller bogholder, men vil tage alle relevante spørgsmål videre, og sørge for at de får svar. På mødet åbner du følgende ark, hvor du søger på kundens navn:
        </Paragraph>
        <Paragraph className="form-note">
          Onboarding Noter - SKAL IKKE OPDATERES
          Lønansvarlig - SKAL IKKE OPDATERES
        </Paragraph>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="payrollFrequency" label="Lønfrekvens" rules={[{ required: true }]}>
            <Select>
              <Option value="monthly">Månedlig</Option>
              <Option value="biweekly">Hver 14. dag</Option>
              <Option value="weekly">Ugentlig</Option>
            </Select>
          </Form.Item>

          <Form.Item name="employeeCount" label="Antal medarbejdere til lønhåndtering" rules={[{ required: true }]}>
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item name="vatFrequency" label="Momsfrekvens" rules={[{ required: true }]}>
            <Select>
              <Option value="monthly">Månedlig</Option>
              <Option value="quarterly">Kvartalsvis</Option>
              <Option value="biannual">Halvårlig</Option>
              <Option value="annual">Årlig</Option>
            </Select>
          </Form.Item>

          <Form.Item name="accountingSoftware" label="Regnskabsprogram" rules={[{ required: true }]}>
            <Input placeholder="Hvilket regnskabsprogram bruger du i dag?" />
          </Form.Item>

          <Form.Item name="debtorManagement" label="Debitorstyring - Kontant" rules={[{ required: true }]}>
            <Input placeholder="Bruger du andre softwares? Det kan være til løn, fakturering, bilagshåndtering, lagerstyring osv." />
          </Form.Item>

          <Form.Item name="creditorManagement" label="Kreditorstyring" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="paymentGateway" label="Betalingsgateway" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="debtorManagementCredit" label="Debitorstyring - Kredit" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="creditorManagementReceipts" label="Kreditorstyring - Kvitteringer" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="payrollSoftware" label="Lønprogram" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="projectTimeManagement" label="Projekt- og tidsstyring">
            <Input />
          </Form.Item>

          <Form.Item name="subscriptionManagement" label="Abonnementsstyring">
            <Input />
          </Form.Item>

          <Form.Item name="inventoryManagement" label="Lagerstyring">
            <Input />
          </Form.Item>

          <Form.Item name="otherSoftware" label="Andre softwares">
            <Input />
          </Form.Item>

          <Form.Item name="bankIntegration" label="Kan bankkonto integreres i bogføringssystem" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nej</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="bank" label="Hvilken bank bruger du?">
            <Input />
          </Form.Item>

          <Form.Item name="companyCar" label="Firmabil" rules={[{ required: true }]}>
            <Input placeholder="Har du firmabil? Hvis ja, hvor mange? Hvilken farve har nummerpladerne?" />
          </Form.Item>

          <Form.Item name="accountPurchases" label="Køb på konto" rules={[{ required: true }]}>
            <Input placeholder="Er der nogle steder hvor du køber på konto?" />
          </Form.Item>

          <Form.Item name="invoiceEmail" label="Fakturamail">
            <Input placeholder="Har du en fakturamail?" />
          </Form.Item>

          <Form.Item name="electronicDocuments" label="Håndtering af elektroniske bilag">
            <TextArea placeholder="Hvad gør du når du får elektroniske bilag?" />
          </Form.Item>

          <Form.Item name="physicalDocuments" label="Håndtering af fysiske bilag">
            <TextArea placeholder="Hvad gør du når du får fysiske bilag?" />
          </Form.Item>

          <Form.Item name="onboardingTasks" label="Onboarding opgaver" rules={[{ required: true }]}>
            <Checkbox.Group>
              <Space direction="vertical">
                <Checkbox value="questions">Har du nogle spørgsmål?</Checkbox>
                <Checkbox value="companyInfo">Er der noget vi skal vide, vedr. din virksomhed eller regnskab?</Checkbox>
                <Checkbox value="additionalHelp">Er der noget udover det aftalte, du har behov for hjælp til?</Checkbox>
                <Checkbox value="taxAccess">Gå herefter til Adgange til SKAT</Checkbox>
                <Checkbox value="kycInfo">Informer herefter om KYC</Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>

          <Paragraph className="kyc-info">
            "Vi er underlagt hvidvaskloven, og forpligtet til at lave hvidvaskkontrol af vores kunder. Derfor vil du efter mødet her modtage en mail fra Creditro, som er det tool vi bruger til at indsamle informationer. Det er de samme informationer som du er vant til at give til banken. Det er vigtigt at du besvarer den, da vi egentlig ikke må påbegynde arbejdet inden den er besvaret."
          </Paragraph>

          <Form.Item name="regNumber" label="Reg nr.">
            <Input />
          </Form.Item>

          <Form.Item name="accountNumber" label="Konto nr.">
            <Input />
          </Form.Item>

          <Form.Item name="cprNumber" label="CPR nr.">
            <Input />
          </Form.Item>

          <Form.Item name="activeCustomer" label="Aktiv kunde" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nej</Radio>
            </Radio.Group>
          </Form.Item>

          <Paragraph className="next-steps">
            Informer om hvad der sker nu:
            "Du modtager en email med information om din tilknyttede bogholder. Han giver dig et kald indenfor et par dage - der kan gå lidt ekstra ift. årsregnskabet her, så måske bliver det først næste uge hvor bogholder introducerer sig selv, samt tager en kort snak om opgaverne."
            Fremadrettet går kommunikation primært til Dennis.
          </Paragraph>

          <Form.Item name="responsibleBookkeeper" label="Ansvarlig bogholder" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="responsibleAuditor" label="Ansvarlig revisor for årsregnskab" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="responsiblePayrollBookkeeper" label="Ansvarlig lønbogholder" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CustomerOnboardingForm;