import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Avatar, Tooltip, Skeleton } from 'antd';
import './KanbanView.css';

function KanbanView({ columns, data, kanbanStatusColumn, onDragEnd, onUpdate, users, loading }) {
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  if (loading) {
    return (
      <div className="skeleton-kanban">
        {[1, 2, 3, 4].map((column) => (
          <div key={column} className="skeleton-column">
            <Skeleton.Input style={{ width: '100%', marginBottom: '16px' }} active />
            <Skeleton active paragraph={{ rows: 5 }} />
          </div>
        ))}
      </div>
    );
  }

  if (!kanbanStatusColumn) {
    return <div>No status column set for Kanban view. Please set a status column in the table settings.</div>;
  }

  const statusOptions = kanbanStatusColumn.options?.filter(option => option.label) || [];

  if (statusOptions.length === 0) {
    return <div>No status options defined for the Kanban view. Please add status options in the column settings.</div>;
  }

  const getItemsForStatus = (status) => {
    return localData.filter(item => item[kanbanStatusColumn.name] === status);
  };

  const renderCardContent = (item) => {
    const taskColumn = columns.find(col => col.name === 'Task');
    const assigneeColumn = columns.find(col => col.name === 'Assignee');
    const workDateColumn = columns.find(col => col.name === 'Work Date');
    const dueDateColumn = columns.find(col => col.name === 'Due Date');

    const renderAssignee = (assigneeId) => {
      const user = users.find(u => u.id === assigneeId);
      if (user && user.name && user.surname) {
        const initials = `${user.name[0]}${user.surname[0]}`.toUpperCase();
        return (
          <Tooltip key={user.id} title={`${user.name} ${user.surname}`}>
            <Avatar style={{ backgroundColor: '#f56a00' }}>
              {initials}
            </Avatar>
          </Tooltip>
        );
      }
      return null;
    };

    return (
      <>
        <h4 className="kanban-card-title">{item[taskColumn.name]}</h4>
        {assigneeColumn && (
          <div className="kanban-card-assignee">
            {Array.isArray(item[assigneeColumn.name]) ? 
              item[assigneeColumn.name].map(renderAssignee) : 
              renderAssignee(item[assigneeColumn.name])
            }
          </div>
        )}
        <div className="kanban-card-dates">
          {workDateColumn && (
            <span>Work: {item[workDateColumn.name]}</span>
          )}
          {dueDateColumn && (
            <span>Due: {item[dueDateColumn.name]}</span>
          )}
        </div>
      </>
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const updatedItem = localData.find(item => item.id.toString() === draggableId);
      if (updatedItem) {
        const newStatus = destination.droppableId;
        
        // Optimistically update the local state
        setLocalData(prevData => 
          prevData.map(item => 
            item.id.toString() === draggableId 
              ? { ...item, [kanbanStatusColumn.name]: newStatus }
              : item
          )
        );

        // Call onUpdate with a callback for error handling
        onUpdate(updatedItem.id, { [kanbanStatusColumn.name]: newStatus }, (error) => {
          if (error) {
            // Revert the change if there's an error
            setLocalData(prevData => 
              prevData.map(item => 
                item.id.toString() === draggableId 
                  ? { ...item, [kanbanStatusColumn.name]: source.droppableId }
                  : item
              )
            );
          }
        });
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="kanban-board">
        {statusOptions.map((status) => {
          const itemsInStatus = getItemsForStatus(status.label);
          return (
            <div key={status.label} className="kanban-column">
              <h3 className="kanban-column-title" style={{ backgroundColor: status.color }}>
                {status.label} <span className="kanban-column-count">{itemsInStatus.length}</span>
              </h3>
              <Droppable droppableId={status.label} type="CARD">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="kanban-column-content"
                  >
                    {itemsInStatus.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`kanban-card ${snapshot.isDragging ? 'is-dragging' : ''}`}
                          >
                            {renderCardContent(item)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          );
        })}
      </div>
    </DragDropContext>
  );
}

export default KanbanView;