import React from 'react';
import { Card } from 'antd';
import { Line } from 'react-chartjs-2';
import './CustomerDash.css';

function CustomerDash({ customerData }) {
  console.log("CustomerDash received data:", customerData);

  if (!customerData || !customerData.monthly_customer_count || Object.keys(customerData.monthly_customer_count).length === 0) {
    return <div>No customer data available.</div>;
  }

  const monthlyCustomerChart = {
    labels: Object.keys(customerData.monthly_customer_count),
    datasets: [
      {
        label: 'Number of Customers',
        data: Object.values(customerData.monthly_customer_count),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  return (
    <div className="customer-dashboard">
      <h2>Customer Dashboard</h2>
      <Card title="Monthly Customer Count">
        <Line 
          data={monthlyCustomerChart}
          options={{
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Number of Customers'
                }
              },
              x: {
                title: {
                  display: true,
                  text: 'Month'
                }
              }
            }
          }}
        />
      </Card>
    </div>
  );
}

export default CustomerDash;