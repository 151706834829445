import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaSearch, FaFilter, FaExclamationCircle, FaUserFriends, FaHome } from 'react-icons/fa';
import { BsPersonBadge, BsBarChartFill, BsChevronDown, BsChevronUp, BsGrid } from 'react-icons/bs';
import { IoMdPeople } from 'react-icons/io';
import { MdPeople, MdOutlineAccountBalance } from 'react-icons/md';
import { GiBookmark } from 'react-icons/gi';
import { AiOutlineSchedule, AiOutlineFund } from 'react-icons/ai';
import { MdPersonAdd, MdWork } from 'react-icons/md';
import axios from '../api/axiosConfig';
import logoLight from '../assets/logos/tuesday_x500_b.png';
import logoDark from '../assets/logos/tuesday_x500_w.png';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import io from 'socket.io-client';


function Sidebar({ theme }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [customersOpen, setCustomersOpen] = useState(false);
  const [pendingDealsCount, setPendingDealsCount] = useState(0);
  const logo = theme === 'dark' ? logoDark : logoLight;
  const navigate = useNavigate();

  useEffect(() => {
    fetchPendingDealsCount();
    const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:5000');
    socket.on('pending_deal_added', (data) => {
      setPendingDealsCount(data.new_count);
    });
    socket.on('pending_deal_imported', (data) => {
      setPendingDealsCount(data.new_count);
    });
    return () => socket.disconnect();
  }, []);

  const fetchPendingDealsCount = async () => {
    try {
      const response = await axios.get('/account/pending-deals-count');
      setPendingDealsCount(response.data.count);
    } catch (error) {
      console.error('Error fetching pending deals count:', error);
    }
  };

  const handleMainMenuRedirect = () => {
    navigate('/main-menu');
  };

  const topMenuItems = [
    { path: '/home', name: 'Home', icon: <FaHome /> },
    { path: '/work', name: 'My Work', icon: <MdWork /> },
    { path: '/dashboard', name: 'Dashboard', icon: <BsBarChartFill /> },
    {
      name: 'Customers',
      icon: <FaUserFriends />,
      subItems: [
        { path: '/customers/deals', name: 'Deals', badge: pendingDealsCount },
        { path: '/customers/accounts', name: 'Accounts' },
        { path: '/customers/contacts', name: 'Contacts' },
        { path: '/customers/products', name: 'Products & Billing' },
      ],
    },
    { path: '/onboarding', name: 'Onboarding', icon: <MdPersonAdd /> },
  ]

  const menuItems = [
    { path: '/boards/andre-opgaver', name: 'Andre opgaver', icon: <BsPersonBadge /> },
    { path: '/boards/onboarding', name: 'Test Board', icon: <MdPeople /> },
    { path: '/boards/bogforing', name: 'Bogføring', icon: <GiBookmark /> },
    { path: '/boards/activities', name: 'Activities', icon: <AiOutlineSchedule /> },
    { path: '/boards/lon', name: 'Løn', icon: <MdOutlineAccountBalance /> },
    { path: '/boards/moms', name: 'Moms', icon: <AiOutlineFund /> },
    { path: '/boards/arsafslutning', name: 'Årsafslutning', icon: <IoMdPeople /> },
    { path: '/boards/udvikling', name: 'Udvikling', icon: <BsPersonBadge /> },
  ];

  const filteredItems = menuItems.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <BsGrid className="main-menu-icon" onClick={handleMainMenuRedirect} />
        <img src={logo} alt="Logo" className="sidebar-logo" />
      </div>
      <nav className="sidebar-nav sidebar-topmenu">
        {topMenuItems.map((item) => (
          item.subItems ? (
            <div key={item.name} className="dropdown-menu">
              <div className="dropdown-header" onClick={() => setCustomersOpen(!customersOpen)}>
                {item.icon}
                <span>{item.name}</span>
                {pendingDealsCount > 0 && (
                  <span className={`badge ${pendingDealsCount === '!' ? 'badge-error' : ''}`}>
                    {pendingDealsCount === '!' ? <FaExclamationCircle /> : pendingDealsCount}
                  </span>
                )}
                {customersOpen ? <BsChevronUp /> : <BsChevronDown />}
              </div>
              {customersOpen && (
                <div className="dropdown-content">
                  {item.subItems.map((subItem) => (
                    <NavLink to={subItem.path} key={subItem.path}>
                      <span>{subItem.name}</span>
                      {subItem.badge > 0 && (
                        <span className={`badge ${subItem.badge === '!' ? 'badge-error' : ''}`}>
                          {subItem.badge === '!' ? <FaExclamationCircle /> : subItem.badge}
                        </span>
                      )}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <NavLink to={item.path} key={item.path}>
              {item.icon}
              <span>{item.name}</span>
            </NavLink>
          )
        ))}
      </nav>
      <div className="sidebar-search">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FaFilter className="filter-icon" />
      </div>
      <nav className="sidebar-nav">
        {filteredItems.map((item) => (
          <NavLink to={item.path} key={item.path}>
            {item.icon}
            <span>{item.name}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

export default Sidebar;