import React, { useState, useEffect } from 'react';
import { Card, Table, Spin } from 'antd';
import { UserOutlined, CheckCircleOutlined, SafetyOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axiosConfig';
import './OnboardingDashboard.css';

const OnboardingDashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    newDeals: null,
    currentOnboards: null,
    inKYC: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [latestCases] = useState([
    { id: 1, name: 'John Doe', reference: 'REF001', status: 'In Progress', updatedAt: '2023-05-15' },
    { id: 2, name: 'Jane Smith', reference: 'REF002', status: 'Completed', updatedAt: '2023-05-14' },
    { id: 3, name: 'Bob Johnson', reference: 'REF003', status: 'Pending', updatedAt: '2023-05-13' },
    { id: 4, name: 'Alice Brown', reference: 'REF004', status: 'In Progress', updatedAt: '2023-05-12' },
    { id: 5, name: 'Charlie Davis', reference: 'REF005', status: 'Completed', updatedAt: '2023-05-11' },
  ]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/onboarding/deals');
      setDashboardData(response.data.dashboardData);
      // If you need the onboardings data for other purposes:
      // setOnboardings(response.data.onboardings);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'Deal',
      dataIndex: 'deal',
      key: 'deal',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Last Update',
      dataIndex: 'update',
      key: 'update',
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Onboarding Start',
      dataIndex: 'onboardingStart',
      key: 'onboardingStart',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span className={`status ${status.toLowerCase().replace(' ', '-')}`}>
          {status}
        </span>
      ),
    },
  ];

  const renderCardContent = (value) => {
    return (
      <div className="card-content">
        {isLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <h2>{value}</h2>
        )}
      </div>
    );
  };

  return (
    <div className="onboarding-dashboard">
      <h1>Onboarding Dashboard</h1>
      <div className="dashboard-cards">
        <Card className="dashboard-card" onClick={() => navigate('/onboarding/cases?filter=1')}>
          <ExclamationCircleOutlined className="card-icon" />
          {renderCardContent(dashboardData.newDeals)}
          <p>New Deals</p>
        </Card>
        <Card className="dashboard-card" onClick={() => navigate('/onboarding/cases')}>
          <UserOutlined className="card-icon" />
          {renderCardContent(dashboardData.currentOnboards)}
          <p>Current Onboards</p>
        </Card>
        <Card className="dashboard-card" onClick={() => navigate('/onboarding/cases?filter=6')}>
          <SafetyOutlined className="card-icon" />
          {renderCardContent(dashboardData.inKYC)}
          <p>In KYC</p>
        </Card>
        <Card className="dashboard-card">
          <CheckCircleOutlined className="card-icon" />
          <div className="card-content">
            <h2>98</h2>
          </div>
          <p>Completed Onboards</p>
        </Card>
      </div>
      <div className="latest-cases">
        <h2>Latest Updates</h2>
        <Table 
          columns={columns} 
          dataSource={latestCases} 
          rowKey="id" 
          className="dashboard-latest-cases-table"
        />
      </div>
    </div>
  );
};

export default OnboardingDashboard;