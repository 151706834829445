import React, { useState, useEffect } from 'react';
import { Tabs, Typography, Space, Button } from 'antd';
import { HomeOutlined, HistoryOutlined, FileOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import ColumnTypeComponents from './ColumnTypeComponents';
import './TaskView.css';

const { Title } = Typography;

function TaskView({ task, columns, onUpdate, onClose, users, allTasks }) {
  const [activeTab, setActiveTab] = useState('overview');
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);

  useEffect(() => {
    const index = allTasks.findIndex(t => t.id === task.id);
    setCurrentTaskIndex(index);
  }, [task, allTasks]);

  const handleFieldUpdate = (fieldName, value) => {
    onUpdate(task.id, { [fieldName]: value });
  };

  const handleNavigate = (direction) => {
    const newIndex = currentTaskIndex + direction;
    if (newIndex >= 0 && newIndex < allTasks.length) {
      onClose(allTasks[newIndex]);
    }
  };

  const renderOverview = () => (
    <div className="task-overview">
      {columns.map((column) => {
        const Component = ColumnTypeComponents[column.type.toLowerCase()];
        return (
          <div key={column.name} className="task-field">
            <Typography.Text strong>{column.name}:</Typography.Text>
            <Component
              value={task[column.name]}
              onChange={(value) => handleFieldUpdate(column.name, value)}
              column={column}
              users={users}
            />
          </div>
        );
      })}
    </div>
  );

  const renderActivity = () => (
    <div className="task-activity">
      <p>Activity log will be displayed here.</p>
    </div>
  );

  const renderFiles = () => (
    <div className="task-files">
      <p>Files related to this task will be displayed here.</p>
    </div>
  );

  const items = [
    {
      key: 'overview',
      label: (
        <span>
          <HomeOutlined />
          Overview
        </span>
      ),
      children: renderOverview(),
    },
    {
      key: 'activity',
      label: (
        <span>
          <HistoryOutlined />
          Activity
        </span>
      ),
      children: renderActivity(),
    },
    {
      key: 'files',
      label: (
        <span>
          <FileOutlined />
          Files
        </span>
      ),
      children: renderFiles(),
    },
  ];

  return (
    <div className="task-view">
      <div className="task-view-header">
        <Title level={2}>{task.Task}</Title>
        <div className="task-navigation">
          <Button
            icon={<ArrowUpOutlined />}
            onClick={() => handleNavigate(-1)}
            disabled={currentTaskIndex === 0}
          />
          <Button
            icon={<ArrowDownOutlined />}
            onClick={() => handleNavigate(1)}
            disabled={currentTaskIndex === allTasks.length - 1}
          />
        </div>
      </div>
      <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} />
      <div className="task-view-footer">
        <Space>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" onClick={onClose}>
            Save and Close
          </Button>
        </Space>
      </div>
    </div>
  );
}

export default TaskView;