import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, message } from 'antd';
import { RobotOutlined } from '@ant-design/icons';
import tuesdayDark from '../assets/logos/tuesday_x500_w.png';
import tuesdayLight from '../assets/logos/tuesday_x500_b.png';
import digidashboardDark from '../assets/logos/dashboard_x500_w.png';
import digidashboardLight from '../assets/logos/dashboard_x500_b.png';
import './MainMenu.css';

function MainMenu({ theme, user }) {
  const navigate = useNavigate();
  const tuesdayLogo = theme === 'dark' ? tuesdayDark : tuesdayLight;
  const digidashboardLogo = theme === 'dark' ? digidashboardDark : digidashboardLight;

  console.log('User object:', user);
  console.log('User permission level:', user?.permission_level);

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleAppSelection = (app) => {
    if (app === 'crm' && user.permission_level >= 3) {
      console.log('Navigating to CRM');
      navigate('/home');
    } else if (app === 'dashboard' && user.permission_level >= 2) {
      navigate('/digi-dashboard');
    } else if (app === 'bot' && user.permission_level >= 1) {
      navigate('/bot');
    } else {
      console.log('Permission denied');
      message.error('You do not have permission to access this application');
    }
  };

  return (
    <div className="main-menu">
      <h1>Welcome to Digi-Tal ERP</h1>
      <div className="app-selection">
        <Card
          hoverable
          className={`app-card ${user.permission_level < 2 ? 'disabled' : ''}`}
          onClick={() => handleAppSelection('dashboard')}
        >
          <img src={digidashboardLogo} alt="Dashboard Logo" className="dashboard-logo" />
          <p>View and analyze business data</p>
          {user.permission_level < 2 && (
            <p className="permission-message">No Access</p>
          )}
        </Card>
        <Card
          hoverable
          className={`app-card ${user.permission_level < 1 ? 'disabled' : ''}`}
          onClick={() => handleAppSelection('bot')}
        >
          <div className="bot-logo-container">
            <RobotOutlined className="bot-icon" />
            <span className="bot-text">Digi-Regnskabsbot</span>
          </div>
          <p>AI Chatbot for all your accounting questions</p>
          {user.permission_level < 1 && (
            <p className="permission-message">No Access</p>
          )}
        </Card>
        <Card
          hoverable
          className={`app-card ${user.permission_level < 3 ? 'disabled' : ''}`}
          onClick={() => handleAppSelection('crm')}
        >
          <img src={tuesdayLogo} alt="Tuesday Logo" className="tuesday-logo" />
          <p>CRM & Task Management</p>
          {user.permission_level < 3 && (
            <p className="permission-message">No Access</p>
          )}
        </Card>
      </div>
    </div>
  );
}

export default MainMenu;