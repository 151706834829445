import React, { useState, useEffect } from 'react';
import { Modal, Form, Switch, Select, Button, message, Tooltip } from 'antd';
import axios from '../api/axiosConfig';
import './UserSettings.css';

const { Option } = Select;

const preSpecifiedColors = [
  '#1890ff', '#52c41a', '#fa8c16', '#722ed1', '#eb2f96', '#f5222d', '#13c2c2', '#faad14',
  '#2f54eb', '#fa541c', '#237804', '#0050b3', '#391085', '#cf1322', '#006d75', '#ad6800',
  '#1d39c4', '#a8071a', '#5c0011', '#003a8c'
];

function UserSettings({ visible, onClose }) {
  const [form] = Form.useForm();
  const [avatarColor, setAvatarColor] = useState('');
  const [availableColors, setAvailableColors] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (visible) {
      fetchUserSettings();
      fetchAvailableColors();
      fetchUsers();
    }
  }, [visible]);

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get('/user/settings');
      form.setFieldsValue(response.data);
      setAvatarColor(response.data.avatar_color);
    } catch (error) {
      console.error('Error fetching user settings:', error);
      message.error('Failed to load user settings');
      // Set default values if settings couldn't be fetched
      form.setFieldsValue({
        theme: 'dark',
        language: 'en',
        desktop_notifications: false,
        email_notifications: false,
      });
      setAvatarColor(preSpecifiedColors[0]);
    }
  };

  const fetchAvailableColors = async () => {
    try {
      const response = await axios.get('/user/available-colors');
      setAvailableColors(response.data);
    } catch (error) {
      console.error('Error fetching available colors:', error);
      message.error('Failed to load available colors');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const handleSubmit = async (values) => {
    try {
      await axios.put('/user/settings', { ...values, avatar_color: avatarColor });
      message.success('Settings updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating user settings:', error);
      message.error('Failed to update settings');
    }
  };

  const getUserInitials = (color) => {
    const user = users.find(u => u.settings?.avatar_color === color);
    return user ? `${user.name?.[0] || ''}${user.surname?.[0] || ''}`.toUpperCase() : '';
  };

  return (
    <Modal
      title="User Settings"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="theme" label="Default Theme">
          <Select>
            <Option value="light">Light</Option>
            <Option value="dark">Dark</Option>
          </Select>
        </Form.Item>
        <Form.Item name="language" label="Language">
          <Select>
            <Option value="en">English</Option>
            <Option value="da">Danish</Option>
          </Select>
        </Form.Item>
        <Form.Item name="avatar_color" label="Avatar Color">
          <div className="color-picker">
            {preSpecifiedColors.map(color => (
              <Tooltip key={color} title={availableColors.includes(color) ? '' : getUserInitials(color)}>
                <div
                  className={`color-option ${color === avatarColor ? 'selected' : ''} ${availableColors.includes(color) ? '' : 'unavailable'}`}
                  style={{ backgroundColor: color }}
                  onClick={() => availableColors.includes(color) && setAvatarColor(color)}
                >
                  {!availableColors.includes(color) && getUserInitials(color)}
                </div>
              </Tooltip>
            ))}
          </div>
        </Form.Item>
        <Form.Item name="desktop_notifications" valuePropName="checked" label="Desktop Notifications">
          <Switch />
        </Form.Item>
        <Form.Item name="email_notifications" valuePropName="checked" label="Email Notifications">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Settings
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UserSettings;