import React, { useState, useEffect } from 'react';
import axios from '../api/axiosConfig';
import { Tabs, DatePicker, Spin, message } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Bar, Pie } from 'react-chartjs-2';
import './Dashboard.css';

const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [activeTab, setActiveTab] = useState('sales');
  const [loading, setLoading] = useState(true);
  const [allSalesData, setAllSalesData] = useState(null);
  const [filteredSalesData, setFilteredSalesData] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);

  const items = [
    { key: 'tasks', label: 'Tasks' },
    { key: 'customers', label: 'Customers' },
    { key: 'sales', label: 'Sales' },
  ];

  useEffect(() => {
    if (activeTab === 'sales') {
      fetchSalesData();
    }
  }, [activeTab]);

  const fetchSalesData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/dashboard/sales');
      setAllSalesData(response.data);
      setFilteredSalesData(response.data);
    } catch (error) {
      console.error('Error fetching sales data:', error);
      message.error('Failed to fetch sales data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates && dates[0] && dates[1]) {
      const startDate = dates[0].toDate();
      const endDate = dates[1].toDate();
      filterSalesData(startDate, endDate);
    } else {
      setFilteredSalesData(allSalesData);
    }
  };

  const filterDataByDateRange = (data, startDate, endDate) => {
    if (!data || !data.datasets) {
      console.error('Invalid data structure for filtering:', data);
      return data; // Return the original data if it's invalid
    }

    return {
      ...data,
      datasets: data.datasets.map(dataset => {
        if (!Array.isArray(dataset.data) || !Array.isArray(data.labels)) {
          console.error('Invalid dataset structure:', dataset);
          return dataset; // Return the original dataset if it's invalid
        }

        return {
          ...dataset,
          data: dataset.data.filter((_, index) => {
            const date = new Date(data.labels[index]);
            return date >= startDate && date <= endDate;
          })
        };
      }),
      labels: data.labels.filter(label => {
        const date = new Date(label);
        return date >= startDate && date <= endDate;
      })
    };
  };

  const filterSalesData = (startDate, endDate) => {
    if (!allSalesData) {
      console.error('No sales data available for filtering');
      return;
    }

    const filteredData = Object.keys(allSalesData).reduce((acc, key) => {
      acc[key] = filterDataByDateRange(allSalesData[key], startDate, endDate);
      return acc;
    }, {});

    setFilteredSalesData(filteredData);
  };

  const renderChart = (chartType, data, options, title) => {
    if (!data || !data.labels || !data.datasets) {
      return <div>No data available for {title}</div>;
    }

    const ChartComponent = chartType === 'line' ? Line : chartType === 'bar' ? Bar : Pie;
    return (
      <div className="chart-container">
        <h3>{title}</h3>
        <ChartComponent data={data} options={options} />
      </div>
    );
  };

  const renderSalesDashboard = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <Spin size="large" />
        </div>
      );
    }

    if (!filteredSalesData) {
      return <div>No data available</div>;
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    return (
      <div className="charts-grid">
        {Object.entries(filteredSalesData).map(([key, data]) => {
          let chartType = 'line';
          if (key === 'dealProgress' || key === 'leadsCreatedByUsers' || key === 'averageValueOfWonDeals') {
            chartType = 'bar';
          } else if (key === 'dealConversion') {
            chartType = 'pie';
          }
          return renderChart(chartType, data, chartOptions, key);
        })}
      </div>
    );
  };

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      <div className="dashboard-controls">
        <Tabs activeKey={activeTab} onChange={setActiveTab} items={items} />
        {activeTab === 'sales' && (
          <RangePicker onChange={handleDateRangeChange} value={dateRange} />
        )}
      </div>
      {activeTab === 'sales' && renderSalesDashboard()}
      {activeTab === 'tasks' && <div>Tasks dashboard content</div>}
      {activeTab === 'customers' && <div>Customers dashboard content</div>}
    </div>
  );
}

export default Dashboard;