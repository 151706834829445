import React from 'react';
import { Modal, List, Avatar, Typography } from 'antd';
import moment from 'moment-timezone';
import './ActivityScreen.css';

const { Text } = Typography;

function ActivityScreen({ visible, onClose, users, currentUser }) {

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  const getDisplayName = (user) => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || user.email || 'Unknown User';
  };

  const getLocalTime = (locationInfo) => {
    if (!locationInfo || !locationInfo.timezone) return 'Unknown';
    return moment().tz(locationInfo.timezone).format('HH:mm');
  };

  const getLocation = (locationInfo) => {
    if (!locationInfo) return 'Unknown';
    return `${locationInfo.city}, ${locationInfo.country}`;
  };

  return (
    <Modal
      title="Online Team Members"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <List
        itemLayout="horizontal"
        dataSource={users}
        renderItem={user => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{ backgroundColor: user.settings?.avatar_color || '#f56a00' }}
                >
                  {getInitials(user.name, user.surname)}
                </Avatar>
              }
              title={getDisplayName(user)}
              description={
                <>
                  <Text>{user.email}</Text>
                  <br />
                  <Text>{getLocation(user.location_info)}</Text>
                  <br />
                  <Text>Local time: {getLocalTime(user.location_info)}</Text>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
}

export default ActivityScreen;