import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Table, Avatar, Tag, Button, Dropdown, Menu, Input, DatePicker, Select, Tooltip, Spin, Badge } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import './Work.css';
import moment from 'moment';
import axios from '../api/axiosConfig';
const { RangePicker } = DatePicker;

function Work() {
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedTodo, setSelectedTodo] = useState('todo');
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [customDateRange, setCustomDateRange] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assignees, setAssignees] = useState([]);
  const [userColors, setUserColors] = useState({});
  const [personalTasks, setPersonalTasks] = useState([]);

  // Move this useEffect to the top
  useEffect(() => {
    fetchCurrentUser();
    fetchUsers();
    fetchPersonalTasks();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
      setUsersLoaded(true);
      const colors = {};
      response.data.forEach(user => {
        colors[user.id] = user.settings?.avatar_color || '#f56a00';
      });
      setUserColors(colors);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsersLoaded(true); // Set to true even on error to allow rendering
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get('/user/current');
      setCurrentUser(response.data);
      setSelectedAssignee([response.data.id]);
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const fetchTasks = useCallback(async () => {
    try {
      const assigneeIds = selectedAssignee.length > 0 ? selectedAssignee : (currentUser ? [currentUser.id] : []);
      const params = new URLSearchParams({
        assignees: assigneeIds.join(','),
      });
      const response = await axios.get(`/table/assigned_tasks?${params}`);
      console.log('Fetched tasks:', response.data);
      setAllTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  }, [currentUser, selectedAssignee]);

  const fetchPersonalTasks = async (includeDeal = true) => {
    try {
      const response = await axios.get('/ptask', { params: { include_deal: includeDeal } });
      setPersonalTasks(response.data);
    } catch (error) {
      console.error('Error fetching personal tasks:', error);
    }
  };

  const filteredTasks = useMemo(() => {
    return allTasks.filter((task) => {
      const matchesSearch = task.data.Task.toLowerCase().includes(searchText.toLowerCase()) ||
        task.board_name.toLowerCase().includes(searchText.toLowerCase());
      
      const matchesPeriod = selectedPeriod ? isTaskInPeriod(task, selectedPeriod) : true;
      const matchesTodo = selectedTodo === 'todo' ? task.data.Status !== 'Done' :
                          selectedTodo === 'done' ? task.data.Status === 'Done' :
                          selectedTodo === 'overdue' ? isTaskOverdue(task) : true;
      
      return matchesSearch && matchesPeriod && matchesTodo;
    });
  }, [allTasks, searchText, selectedPeriod, selectedTodo]);

  const sortedAndFilteredTasks = useMemo(() => {
    return filteredTasks.sort((a, b) => {
      const dateA = moment(a.data['Due Date']);
      const dateB = moment(b.data['Due Date']);
      return dateA.diff(dateB);
    });
  }, [filteredTasks]);

  const allTasksData = useMemo(() => {
    const boardTasks = sortedAndFilteredTasks.map(task => ({
      ...task,
      key: `board-${task.id}`,
      task_type: 'board',
    }));
    const personalTasksData = personalTasks.map(task => ({
      ...task,
      key: `personal-${task.id}`,
      task_type: 'personal',
      data: {
        Task: task.text,
        'Due Date': null, // Leave due date blank for personal tasks
        'Work Date': task.task_date,
        Status: '', // Leave status blank for personal tasks
        Assignee: [task.user_id], // Use user_id for assignee
      },
      board_name: 'Personal Task',
      deal_id: task.deal_id,
      deal_name: task.deal ? task.deal.name : null,
    }));
    return [...boardTasks, ...personalTasksData];
  }, [sortedAndFilteredTasks, personalTasks]);

  useEffect(() => {
    if (currentUser) {
      fetchTasks();
    }
  }, [currentUser, fetchTasks, selectedAssignee]);

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const response = await axios.get('/user');
        setAssignees(response.data);
      } catch (error) {
        console.error('Error fetching assignees:', error);
      }
    };
    fetchAssignees();
  }, []);

  const getRowClassName = (record) => {
    const dueDate = moment(record.data['Due Date']);
    const today = moment().startOf('day');
    if (dueDate.isSame(today, 'day')) {
      return 'due-today';
    } else if (dueDate.isBefore(today)) {
      return 'overdue';
    }
    return '';
  };

  const columns = [
    {
      title: 'Task',
      dataIndex: ['data', 'Task'],
      key: 'task',
    },
    {
      title: 'Assignee',
      dataIndex: ['data', 'Assignee'],
      key: 'assignee',
      render: (assigneeIds, record) => (
        <Avatar.Group>
          {Array.isArray(assigneeIds) ? assigneeIds.map((id) => {
            const user = users.find(u => u.id === id);
            if (user && user.name && user.surname) {
              const initials = `${user.name[0]}${user.surname[0]}`.toUpperCase();
              return (
                <Tooltip key={user.id} title={`${user.name} ${user.surname}`}>
                  <Avatar style={{ backgroundColor: userColors[id] || '#f56a00' }}>
                    {initials}
                  </Avatar>
                </Tooltip>
              );
            }
            return null;
          }) : null}
        </Avatar.Group>
      ),
    },
    {
      title: 'Status',
      dataIndex: ['data', 'Status'],
      key: 'status',
      render: (status, record) => record.task_type === 'personal' ? null : <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: 'Due Date',
      dataIndex: ['data', 'Due Date'],
      key: 'dueDate',
      render: (date, record) => {
        if (record.task_type === 'personal') return null;
        const dueDate = moment(date);
        const today = moment().startOf('day');
        let className = '';
        if (dueDate.isSame(today, 'day')) {
          className = 'due-today';
        } else if (dueDate.isBefore(today)) {
          className = 'overdue';
        }
        return <span className={className}>{dueDate.format('YYYY-MM-DD')}</span>;
      },
    },
    {
      title: 'Work Date',
      dataIndex: ['data', 'Work Date'],
      key: 'workDate',
      render: (date, record) => {
        const workDate = moment(date);
        return workDate.isValid() ? workDate.format('YYYY-MM-DD') : null;
      },
    },
    {
      title: 'Board',
      dataIndex: 'board_name',
      key: 'boardName',
      render: (boardName, record) => {
        if (record.task_type === 'personal') {
          return (
            <Badge
              count="Personal Task"
              style={{
                backgroundColor: '#feff9c',
                color: '#1A202C',
                fontFamily: "'Indie Flower', cursive",
                fontWeight: 'bold',
                fontSize: '14px',
                padding: '0 8px',
              }}
            />
          );
        }
        return boardName;
      },
    },
    {
      title: 'Deal',
      dataIndex: 'deal_name',
      key: 'deal',
      render: (deal_name, record) => (
        deal_name ? (
          <a href={`/deals/${record.deal_id}`}>{deal_name}</a>
        ) : null
      ),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'Not Started':
        return 'default';
      case 'Working on it':
        return 'processing';
      case 'Stuck':
        return 'error';
      case 'Done':
        return 'success';
      default:
        return 'default';
    }
  };

  const getPeriodLabel = (key) => {
    const dateRange = getDateRange(key);
    switch (key) {
      case 'today':
        return 'To do today';
      case 'thisMonth':
        return 'This month';
      case 'lastMonth':
        return 'Last month';
      case 'thisAndNextMonth':
      case 'next3Months':
      case 'custom':
        return `${dateRange[0].format('YYYY-MM-DD')} - ${dateRange[1].format('YYYY-MM-DD')}`;
      default:
        return 'Period';
    }
  };

  const getDateRange = (key) => {
    const today = moment();
    switch (key) {
      case 'today':
        return [today, today];
      case 'thisMonth':
        return [today.clone().startOf('month'), today.clone().endOf('month')];
      case 'lastMonth':
        return [today.clone().subtract(1, 'month').startOf('month'), today.clone().subtract(1, 'month').endOf('month')];
      case 'thisAndNextMonth':
        return [today.clone().startOf('month'), today.clone().add(1, 'month').endOf('month')];
      case 'next3Months':
        return [today, today.clone().add(3, 'months')];
      case 'custom':
        return customDateRange || [null, null];
      default:
        return [null, null];
    }
  };

  const periodMenu = {
    items: [
      { key: 'today', label: 'To do today' },
      { key: 'thisMonth', label: 'This month' },
      { key: 'lastMonth', label: 'Last month' },
      { key: 'thisAndNextMonth', label: 'This and next month' },
      { key: 'next3Months', label: 'Next 3 months' },
      {
        key: 'custom',
        label: (
          <RangePicker
            value={getDateRange(selectedPeriod)}
            onChange={(dates) => {
              setCustomDateRange(dates);
              setSelectedPeriod('custom');
            }}
          />
        ),
      },
    ],
    onClick: (e) => setSelectedPeriod(e.key),
  };

  const todoOptions = [
    { key: 'todo', label: 'To do' },
    { key: 'overdue', label: 'Overdue' },
    { key: 'done', label: 'Done' },
  ];

  const todoMenu = {
    items: todoOptions.map(option => ({
      key: option.key,
      label: option.label,
    })),
    onClick: (e) => setSelectedTodo(e.key),
  };

  const renderMultiSelect = (options, placeholder, value, setValue) => ({
    items: [
      {
        key: 'content',
        label: (
          <div className="multi-select-dropdown" onClick={(e) => e.stopPropagation()}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={placeholder}
              value={value}
              onChange={setValue}
              options={options.map((option) => ({ 
                label: option.name && option.surname ? `${option.name} ${option.surname}` : option.full_name || `User ${option.id}`, 
                value: option.id 
              }))}
            />
            <div className="multi-select-actions">
              <Button onClick={() => setValue(options.map(o => o.id))}>All</Button>
              <Button onClick={() => setValue([])}>None</Button>
            </div>
          </div>
        ),
      },
    ],
  });

  const topics = ['Bookkeeping', 'Tax Filing', 'Audit', 'Payroll', 'Financial Reporting'];
  const accounts = ['ABC Corp', 'XYZ Ltd', 'LMN Inc', 'PQR Co', 'EFG Enterprises'];

  const isTaskInPeriod = (task, period) => {
    const dueDate = moment(task.data['Due Date']);
    const [startDate, endDate] = getDateRange(period);
    return dueDate.isBetween(startDate, endDate, 'day', '[]');
  };

  const isTaskOverdue = (task) => {
    const dueDate = moment(task.data['Due Date']);
    return dueDate.isBefore(moment(), 'day');
  };

  const renderDropdown = (menu, buttonText, className = '') => (
    <Dropdown 
      menu={menu} 
      trigger={['click']}
    >
      <Button className={className}>
        {buttonText} <DownOutlined />
      </Button>
    </Dropdown>
  );

  if (!usersLoaded) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="work-content">
      <h1>My Work</h1>
      <div className="filter-container">
        {renderDropdown(periodMenu, getPeriodLabel(selectedPeriod), selectedPeriod ? 'filter-active' : '')}
        {renderDropdown(todoMenu, todoOptions.find(option => option.key === selectedTodo)?.label || 'To do', 'filter-active')}
        {renderDropdown(
          renderMultiSelect(topics, 'Select topics', selectedTopics, setSelectedTopics),
          'Topic',
          selectedTopics.length > 0 ? 'filter-active' : ''
        )}
        {renderDropdown(
          renderMultiSelect(accounts, 'Select accounts', selectedAccounts, setSelectedAccounts),
          'Accounts',
          selectedAccounts.length > 0 ? 'filter-active' : ''
        )}
        {renderDropdown(
          renderMultiSelect(users, 'Select assignee', selectedAssignee, setSelectedAssignee),
          selectedAssignee.length > 0 ? `Assignee (${selectedAssignee.length})` : 'Assignee',
          selectedAssignee.length > 0 ? 'filter-active' : ''
        )}
      </div>
      <Input
        prefix={<SearchOutlined />}
        placeholder="Search by account or task name"
        className="work-search-input"
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Table
        dataSource={allTasksData}
        columns={columns}
        loading={loading}
        rowKey={(record) => record.key}
        rowClassName={getRowClassName}
      />
    </div>
  );
}

export default Work;