import React, { useState, useEffect } from 'react';
import { Table, Spin, Button, Modal } from 'antd';
import axios from '../api/axiosConfig';
import './Contacts.css';

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountModalVisible, setAccountModalVisible] = useState(false);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get('/account/contacts');
      setContacts(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setLoading(false);
    }
  };

  const showAccountDetails = (account) => {
    setSelectedAccount(account);
    setAccountModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Accounts',
      dataIndex: 'accounts',
      key: 'accounts',
      render: (accounts) => (
        <span>
          {accounts.map((account, index) => (
            <Button key={account.id} type="link" onClick={() => showAccountDetails(account)}>
              {account.company_name}{index < accounts.length - 1 ? ', ' : ''}
            </Button>
          ))}
        </span>
      ),
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="contacts-container">
      <h1>Contacts</h1>
      <Table
        columns={columns}
        dataSource={contacts}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        className="contacts-table"
      />
      <Modal
        title="Account Details"
        visible={accountModalVisible}
        onCancel={() => setAccountModalVisible(false)}
        footer={null}
      >
        {selectedAccount && (
          <div>
            <p><strong>Company Name:</strong> {selectedAccount.company_name}</p>
            <p><strong>CVR:</strong> {selectedAccount.cvr}</p>
            <p><strong>Kundenummer:</strong> {selectedAccount.kundenummer}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Contacts;
