import React from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Content from '../components/Content';
import Home from './Home';
import Work from './Work';
import Dashboard from './Dashboard';
import Deals from '../customers/Deals';
import Accounts from '../customers/Accounts';
import Contacts from '../customers/Contacts';
import Products from '../customers/Products';
import OnboardingDashboard from '../onboarding/OnboardingDashboard';
import OnboardingCases from '../onboarding/OnboardingCases';
import CustomerOnboardingForm from '../onboarding/forms/CustomerOnboardingForm';
import './Boards.css';

function Boards({ theme, toggleTheme }) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isOnboardingForm = location.pathname.includes('/onboarding/customer-onboarding-form');

  return (
    <div className={`boards ${isOnboardingForm ? 'full-width' : ''}`}>
      {!isOnboardingForm && <Sidebar theme={theme} />}
      <div className={`boards-main ${isOnboardingForm ? 'full-width' : ''}`}>
        {!isOnboardingForm && <Header theme={theme} toggleTheme={toggleTheme} />}
        <div className="boards-content">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/work" element={<Work />} />
            <Route path="/dashboard" element={<Dashboard />} />
            
            <Route path="/customers/deals" element={<Deals />} />
            <Route path="/customers/accounts" element={<Accounts />} />
            <Route path="/customers/contacts" element={<Contacts />} />
            <Route path="/customers/products" element={<Products />} />

            <Route path="/onboarding" element={<OnboardingDashboard />} />
            <Route path="/onboarding/cases" element={<OnboardingCases initialFilter={searchParams.get('filter')} />} />
            <Route path="/onboarding/customer-onboarding-form/:onboardingId" element={<CustomerOnboardingForm />} />

            <Route path="/boards/deals" element={<Content title="Deals" />} />
            <Route path="/boards/accounts" element={<Content title="Accounts" />} />
            <Route path="/boards/people" element={<Content title="People" />} />
            <Route path="/boards/andreopgaver" element={<Content title="Andre opgaver" />} />
            <Route path="/boards/onboarding" element={<Content title="Onboarding" />} />
            <Route path="/boards/bogforing" element={<Content title="Bogfring" />} />
            <Route path="/boards/activities" element={<Content title="Activities" />} />
            <Route path="/boards/lon" element={<Content title="Ln" />} />
            <Route path="/boards/arsafslutning" element={<Content title="Arsafslutning" />} />
            <Route path="/boards/moms" element={<Content title="Moms" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Boards;