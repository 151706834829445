import React from 'react';
import { Spin } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './SalesDash.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function SalesDash({ selectedDate, dateType, salesData }) {
  const filterSalesData = () => {
    if (!salesData || !salesData.datasets || !salesData.datasets[0] || !salesData.labels) {
      return null;
    }

    let startDate, endDate;
    if (dateType === 'custom') {
      startDate = selectedDate[0].toDate();
      endDate = selectedDate[1].toDate();
    } else {
      startDate = selectedDate.date.startOf(dateType).toDate();
      endDate = selectedDate.date.endOf(dateType).toDate();
    }

    // Filter sales data based on selected date range
    const filteredData = {
      labels: salesData.labels,
      datasets: [{
        ...salesData.datasets[0],
        data: salesData.datasets[0].data.map((value, index) => {
          const date = new Date(salesData.labels[index]);
          return (date >= startDate && date <= endDate) ? value : 0;
        })
      }]
    };

    return filteredData;
  };

  const renderSalesChart = () => {
    if (!salesData) {
      return <div className="loading-container"><Spin size="large" /></div>;
    }

    const filteredData = filterSalesData();

    if (!filteredData) {
      return <div className="loading-container">No data available</div>;
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Sales by Product',
        },
      },
    };

    return <Bar data={filteredData} options={options} />;
  };

  return (
    <div className="sales-dashboard">
      <h2>Sales Dashboard</h2>
      <div className="chart-container">
        {renderSalesChart()}
      </div>
    </div>
  );
}

export default SalesDash;