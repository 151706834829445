import React, { useState } from 'react';
import { Spin, Table, Card, Row, Col, Button, Select } from 'antd';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { RobotOutlined } from '@ant-design/icons';
import './InvoiceDash.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function InvoiceDash({ selectedDate, dateType, invoiceData, aiInvoiceAnalysis }) {
  const [dateField, setDateField] = useState('date');
  const [pageSize, setPageSize] = useState(20);
  const [isChartExpanded, setIsChartExpanded] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState(null);

  const formatAmount = (amount) => {
    return `DKK ${Math.round(amount).toLocaleString('da-DK')}`;
  };

  const filterInvoiceData = () => {
    if (!invoiceData || !invoiceData.invoices) return null;

    let startDate, endDate;
    if (dateType === 'custom') {
      startDate = dayjs(selectedDate[0]);
      endDate = dayjs(selectedDate[1]);
    } else {
      startDate = dayjs(selectedDate.date).startOf(dateType);
      endDate = dayjs(selectedDate.date).endOf(dateType);
    }

    const filteredInvoices = invoiceData.invoices.filter(invoice => {
      const invoiceDate = dayjs(invoice[dateField]);
      return invoiceDate.isAfter(startDate) && invoiceDate.isBefore(endDate);
    });

    const paidInvoices = filteredInvoices.filter(i => i.status === 'Paid');
    const unpaidInvoices = filteredInvoices.filter(i => i.status === 'Unpaid' || i.status === 'Overdue');
    const overdueInvoices = filteredInvoices.filter(i => i.status === 'Overdue');

    const totalAmount = filteredInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);
    const paidAmount = paidInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);
    const unpaidAmount = unpaidInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);
    const overdueAmount = overdueInvoices.reduce((sum, invoice) => sum + invoice.amount, 0);

    return {
      ...invoiceData,
      invoices: filteredInvoices,
      summary: {
        ...invoiceData.summary,
        invoiceCount: filteredInvoices.length,
        paidCount: paidInvoices.length,
        unpaidCount: unpaidInvoices.length,
        overdueCount: overdueInvoices.length,
        totalAmount: totalAmount,
        paidAmount: paidAmount,
        unpaidAmount: unpaidAmount,
        overdueAmount: overdueAmount,
      }
    };
  };

  const renderMonthlyTotalsChart = () => {
    if (!invoiceData || !invoiceData.monthlyTotals || !invoiceData.monthlyTotals.labels) {
      console.warn('Invoice data or monthly totals are missing');
      return null;
    }

    const data = {
      labels: [
        ...invoiceData.monthlyTotals.labels,
        ...(invoiceData.monthlyTotals.forecast && invoiceData.monthlyTotals.forecast.length > 0 
          ? ['Forecast 1', 'Forecast 2', 'Forecast 3'] 
          : [])
      ],
      datasets: [
        {
          label: 'Total Invoiced',
          data: invoiceData.monthlyTotals.invoiced,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Total Paid',
          data: invoiceData.monthlyTotals.paid,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Forecast',
          data: invoiceData.monthlyTotals.forecast 
            ? [
                ...Array(invoiceData.monthlyTotals.invoiced.length - 1).fill(null),
                invoiceData.monthlyTotals.invoiced[invoiceData.monthlyTotals.invoiced.length - 1],
                ...invoiceData.monthlyTotals.forecast.slice(1)
              ]
            : [],
          borderColor: 'rgb(255, 159, 64)',
          backgroundColor: 'rgba(255, 159, 64, 0.5)',
          borderDash: [5, 5],
          pointStyle: 'circle',
          pointRadius: 0,
          pointHoverRadius: 5,
        }
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Monthly Invoiced and Paid Totals',
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += formatAmount(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: function(value) {
              return formatAmount(value);
            }
          }
        }
      }
    };

    return (
      <div className="chart-container" onClick={() => setIsChartExpanded(true)}>
        <Line options={options} data={data} />
      </div>
    );
  };

  const renderExpandedChart = () => {
    if (!isChartExpanded) return null;

    return (
      <div className="expanded-chart-overlay" onClick={() => setIsChartExpanded(false)}>
        <div className="expanded-chart-container" onClick={(e) => e.stopPropagation()}>
          {renderMonthlyTotalsChart()}
        </div>
      </div>
    );
  };

  const renderTotalsToDate = () => {
    if (!invoiceData || !invoiceData.summary) return null;

    return (
      <Card title="Totals to Date" className="totals-card">
        <Row gutter={16}>
          <Col span={6}>
            <Card className="total-card">
              <h3 className="total-title">Total Invoiced</h3>
              <div className="card-divider"></div>
              <p className="total-amount">{formatAmount(invoiceData.summary.totalInvoiced)}</p>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="total-card">
              <h3 className="total-title">Total Paid</h3>
              <div className="card-divider"></div>
              <p className="total-amount">{formatAmount(invoiceData.summary.totalPaid)}</p>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="total-card">
              <h3 className="total-title">Total Unpaid</h3>
              <div className="card-divider"></div>
              <p className="total-amount">{formatAmount(invoiceData.summary.totalUnpaid)}</p>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="total-card">
              <h3 className="total-title">Total Overdue</h3>
              <div className="card-divider"></div>
              <p className="total-amount">{formatAmount(invoiceData.summary.totalOverdue)}</p>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  };

  const renderSelectedPeriodData = () => {
    const filteredData = filterInvoiceData();

    if (!filteredData || filteredData.invoices.length === 0) {
      return <div className="no-data-message">No data available for the selected period</div>;
    }

    const formatDate = (date) => {
      if (Array.isArray(date)) {
        return `${date[0].format('MMMM D, YYYY')} - ${date[1].format('MMMM D, YYYY')}`;
      }
      return date.date.format(date.format);
    };

    const selectedPeriod = dateType === 'custom'
      ? formatDate(selectedDate)
      : formatDate(selectedDate);

    const columns = [
      { title: 'Customer', dataIndex: 'customer', key: 'customer' },
      { title: 'Invoice Number', dataIndex: 'invoiceNumber', key: 'invoiceNumber' },
      { 
        title: 'Amount', 
        dataIndex: 'amount', 
        key: 'amount',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount) => formatAmount(amount)
      },
      { 
        title: 'Due Date', 
        dataIndex: 'dueDate', 
        key: 'dueDate',
        render: (dueDate, record) => {
          if (record.key === 'total') {
            return ''; // Return empty string for the total row
          }
          const dueDateObj = dayjs(dueDate);
          const today = dayjs();
          const diff = dueDateObj.diff(today, 'day');
          
          let className = '';
          if (record.status !== 'Paid') {
            if (diff < 0) {
              className = 'past-due';
            } else if (diff <= 7) {
              className = 'due-soon';
            }
          }

          return <span className={className}>{dueDate}</span>;
        }
      },
      { 
        title: 'Status', 
        dataIndex: 'status', 
        key: 'status',
        filters: [
          { text: 'Paid', value: 'Paid' },
          { text: 'Unpaid', value: 'Unpaid' },
          { text: 'Overdue', value: 'Overdue' },
        ],
        onFilter: (value, record) => record.status === value,
        render: (status) => status // Simply render the status as is
      },
    ];

    const dataSource = filteredData.invoices
      .filter(invoice => !filteredStatus || invoice.status === filteredStatus)
      .map((invoice, index) => ({
        ...invoice,
        key: `invoice-${index}`
      }));

    const paidCount = dataSource.filter(i => i.status === 'Paid').length;
    const totalAmount = dataSource.reduce((sum, i) => sum + i.amount, 0);

    const handlePageSizeChange = (current, size) => {
      setPageSize(size);
    };

    const handleStatusFilterChange = (value) => {
      setFilteredStatus(value);
    };

    return (
      <>
        <Card title={`Selected Period: ${selectedPeriod}`} className="period-card">
          <div className="date-type-buttons">
            <Button 
              type={dateField === 'date' ? 'primary' : 'default'} 
              onClick={() => setDateField('date')}
            >
              Invoice Date
            </Button>
            <Button 
              type={dateField === 'dueDate' ? 'primary' : 'default'} 
              onClick={() => setDateField('dueDate')}
            >
              Due Date
            </Button>
          </div>
          <Row gutter={16}>
            <Col span={6}>
              <Card>
                <h3 className="card-title">Invoice</h3>
                <div className="card-divider"></div>
                <p className="card-value">Count: {filteredData.summary.invoiceCount}</p>
                <p className="card-value">Amount: {formatAmount(filteredData.summary.totalAmount)}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <h3 className="card-title">Paid</h3>
                <div className="card-divider"></div>
                <p className="card-value">Count: {filteredData.summary.paidCount}</p>
                <p className="card-value">Amount: {formatAmount(filteredData.summary.paidAmount)}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <h3 className="card-title">Unpaid</h3>
                <div className="card-divider"></div>
                <p className="card-value">Count: {filteredData.summary.unpaidCount}</p>
                <p className="card-value">Amount: {formatAmount(filteredData.summary.unpaidAmount)}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <h3 className="card-title">Overdue</h3>
                <div className="card-divider"></div>
                <p className="card-value">Count: {filteredData.summary.overdueCount}</p>
                <p className="card-value">Amount: {formatAmount(filteredData.summary.overdueAmount)}</p>
              </Card>
            </Col>
          </Row>
          <div className="due-date-explainer">
            <div className="explainer-item">
              <span className="color-box past-due"></span>
              <span>Overdue</span>
            </div>
            <div className="explainer-item">
              <span className="color-box due-soon"></span>
              <span>Due within 7 days</span>
            </div>
          </div>
          <Table 
            dataSource={dataSource} 
            columns={columns} 
            className="invoice-table" 
            pagination={{ 
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              onShowSizeChange: handlePageSizeChange,
            }}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>{formatAmount(totalAmount)}</Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>{`${paidCount} of ${dataSource.length} paid`}</Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </Card>
      </>
    );
  };

  const renderAiAnalysis = () => {
    if (!aiInvoiceAnalysis) return null;

    return (
      <Card 
        title={
          <span>
            <RobotOutlined style={{ marginRight: '8px', color: '#00FF7F' }} />
            Digi-AI Analysis
          </span>
        } 
        className="ai-analysis-card"
      >
        <div className="ai-analysis-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(aiInvoiceAnalysis) }} />
      </Card>
    );
  };

  return (
    <div className="invoice-dashboard">
      <h2>Invoice Dashboard</h2>
      {!invoiceData ? (
        <div className="loading-container"><Spin size="large" /></div>
      ) : (
        <>
          <div className="invoice-container">
            <div className="main-content">
              {renderSelectedPeriodData()}
            </div>
            <div className="invoice-sidebar">
              {renderTotalsToDate()}
              <Card title="Monthly Totals" className="monthly-totals-card">
                <div className="chart-wrapper">
                  {renderMonthlyTotalsChart()}
                </div>
              </Card>
            </div>
          </div>
          <div className="ai-analysis-container">
            {renderAiAnalysis()}
          </div>
          {renderExpandedChart()}
        </>
      )}
    </div>
  );
}

export default InvoiceDash;