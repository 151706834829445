import React from 'react';
import { Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

function RowSettings({ rowId, onAction }) {
  const handleMenuClick = (e) => {
    onAction(e.key, rowId);
  };

  const menuItems = [
    { key: 'open', label: 'Open task' },
    { key: 'openNewTab', label: 'Open in new tab' },
    { key: 'copyLink', label: 'Copy task link' },
    { key: 'moveTo', label: 'Move to' },
    { key: 'duplicate', label: 'Duplicate' },
    { key: 'createBelow', label: 'Create new task below' },
    { key: 'archive', label: 'Archive' },
    { key: 'delete', label: 'Delete' },
  ];

  const menu = <Menu onClick={handleMenuClick} items={menuItems} />;

  return { menu, icon: <MoreOutlined /> };
}

export default RowSettings;