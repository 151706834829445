import React, { useState, useEffect } from 'react';
import { Table, Spin, Button, Modal } from 'antd';
import axios from '../api/axiosConfig';
import './Accounts.css';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [dealModalVisible, setDealModalVisible] = useState(false);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    } finally {
      setLoading(false);
    }
  };

  const showContactDetails = (contact) => {
    setSelectedContact(contact);
    setContactModalVisible(true);
  };

  const showDealDetails = (deal) => {
    setSelectedDeal(deal);
    setDealModalVisible(true);
  };

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
    },
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      render: (contacts) => (
        <span>
          {contacts.map((contact, index) => (
            <Button key={contact.id} type="link" onClick={() => showContactDetails(contact)}>
              {contact.name}{index < contacts.length - 1 ? ', ' : ''}
            </Button>
          ))}
        </span>
      ),
    },
    {
      title: 'Deals',
      dataIndex: 'deals',
      key: 'deals',
      render: (deals) => (
        <span>
          {deals.map((deal, index) => (
            <Button key={deal.id} type="link" onClick={() => showDealDetails(deal)}>
              {deal.name}{index < deals.length - 1 ? ', ' : ''}
            </Button>
          ))}
        </span>
      ),
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="accounts-container">
      <h1>Customer Accounts</h1>
      <Table
        columns={columns}
        dataSource={accounts}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        className="accounts-table"
      />
      <Modal
        title="Contact Details"
        open={contactModalVisible}
        onCancel={() => setContactModalVisible(false)}
        footer={null}
      >
        {selectedContact && (
          <div>
            <p><strong>Name:</strong> {selectedContact.name}</p>
            <p><strong>Email:</strong> {selectedContact.email}</p>
            <p><strong>Phone:</strong> {selectedContact.phone}</p>
          </div>
        )}
      </Modal>
      <Modal
        title="Deal Details"
        open={dealModalVisible}
        onCancel={() => setDealModalVisible(false)}
        footer={null}
      >
        {selectedDeal && (
          <div>
            <p><strong>Name:</strong> {selectedDeal.name}</p>
            <p><strong>CVR:</strong> {selectedDeal.cvr}</p>
            <p><strong>Email:</strong> {selectedDeal.email}</p>
            <p><strong>Phone:</strong> {selectedDeal.phone}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Accounts;