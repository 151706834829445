import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { FaTimes } from 'react-icons/fa';
import { PlusOutlined, BarChartOutlined, FontSizeOutlined, TeamOutlined, DownOutlined, CalendarOutlined, NumberOutlined, FileOutlined, FunctionOutlined, LinkOutlined, CheckSquareOutlined, FlagOutlined, FieldTimeOutlined, TagsOutlined, PhoneOutlined, MailOutlined, CloseOutlined } from '@ant-design/icons';
import './AddColumnModal.css';

const columnTypes = [
  { name: 'Status', icon: <BarChartOutlined />, color: '#1890ff' }, // Blue for status
  { name: 'Text', icon: <FontSizeOutlined />, color: '#722ed1' }, // Purple for text
  { name: 'People', icon: <TeamOutlined />, color: '#13c2c2' }, // Cyan for people
  { name: 'Dropdown', icon: <DownOutlined />, color: '#52c41a' }, // Green for dropdown
  { name: 'Date', icon: <CalendarOutlined />, color: '#faad14' }, // Gold for date
  { name: 'Numbers', icon: <NumberOutlined />, color: '#fa8c16' }, // Orange for numbers
  { name: 'Files', icon: <FileOutlined />, color: '#a0d911' }, // Lime for files
  { name: 'Formula', icon: <FunctionOutlined />, color: '#eb2f96' }, // Pink for formula
  { name: 'Connect', icon: <LinkOutlined />, color: '#1890ff' }, // Blue for connect
  { name: 'Checkbox', icon: <CheckSquareOutlined />, color: '#52c41a' }, // Green for checkbox
  { name: 'Priority', icon: <FlagOutlined />, color: '#f5222d' }, // Red for priority
  { name: 'Timeline', icon: <FieldTimeOutlined />, color: '#722ed1' }, // Purple for timeline
  { name: 'Label', icon: <TagsOutlined />, color: '#faad14' }, // Gold for label
  { name: 'Phone', icon: <PhoneOutlined />, color: '#13c2c2' }, // Cyan for phone
  { name: 'Email', icon: <MailOutlined />, color: '#1890ff' }, // Blue for email
  { name: 'Assignee', icon: <TeamOutlined />, color: '#13c2c2' }, // Cyan for assignee
];

function AddColumnModal({ onAdd, onClose }) {
  const [columnName, setColumnName] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const filteredTypes = columnTypes.filter(type => 
    type.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (columnName && selectedType) {
      onAdd(columnName, selectedType);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="add-column-modal-overlay" onClick={handleOverlayClick}>
      <div className="add-column-modal">
        <Button
          className="column-close-button"
          icon={<CloseOutlined />}
          onClick={onClose}
          type="text"
        />
        <h2>Add New Column</h2>
        <form onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            type="text"
            placeholder="Enter column name"
            value={columnName}
            onChange={(e) => setColumnName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Search column types"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="column-types-grid">
            {filteredTypes.map(type => (
              <button
                key={type.name}
                type="button"
                className={`column-type-button ${selectedType === type.name ? 'selected' : ''}`}
                onClick={() => setSelectedType(type.name)}
              >
                <span className="column-type-icon" style={{ color: type.color }}>{type.icon}</span>
                <span className="column-type-name">{type.name}</span>
              </button>
            ))}
          </div>
          <div className="modal-actions">
            <Button key="cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleSubmit}
              disabled={!columnName || !selectedType}
            >
              Add Column
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddColumnModal;