import React, { useState, useCallback } from 'react';
import { Input, InputNumber, DatePicker, Select, Checkbox, Upload, Button, Tag, Dropdown, Modal } from 'antd';
import { UploadOutlined, EditOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs'; // Replace moment with dayjs

const { Option } = Select;

export const columnDefaults = {
    text: '',
    numbers: 0,
    date: dayjs().format('YYYY-MM-DD'), // Use dayjs here
    dropdown: 'option1',
    checkbox: false,
    status: '',
    files: [],
    label: [],
    assignee: [], // Added this line
};

const preSpecifiedColors = [
  '#1890ff', // Blue
  '#52c41a', // Green
  '#fa8c16', // Orange
  '#722ed1', // Purple
  '#eb2f96', // Pink
  '#f5222d', // Red
  '#13c2c2', // Cyan
  '#faad14', // Gold
  '#2f54eb', // Geekblue
  '#fa541c', // Volcano
  '#237804', // Dark green
  '#0050b3', // Dark blue
  '#391085', // Dark purple
  '#cf1322', // Dark red
  '#006d75', // Dark cyan
  '#ad6800', // Dark gold
  '#1d39c4', // Dark geekblue
  '#a8071a', // Dark volcano
  '#5c0011', // Very dark red
  '#003a8c', // Very dark blue
];

const TextComponent = ({ value, onChange, onBlur }) => {
    const [localValue, setLocalValue] = useState(value);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    };

    return (
        <Input
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
            onBlur={() => {
                onChange(localValue);
                onBlur(localValue);
            }}
            onKeyDown={handleKeyDown}
        />
    );
};

const NumbersComponent = ({ value, onChange, onBlur }) => {
    const [localValue, setLocalValue] = useState(value);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    };

    const debouncedChange = useCallback(
        debounce((value) => {
            onChange(value);
        }, 500),
        [onChange]
    );

    const handleChange = (newValue) => {
        setLocalValue(newValue);
        debouncedChange(newValue);
    };

    const handleBlur = () => {
        onBlur(localValue);
    };

    return (
        <InputNumber
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
        />
    );
};

const DateComponent = ({ value, onChange, onBlur }) => {
    const handleChange = (date, dateString) => {
        const formattedDate = date ? date.format('YYYY-MM-DD') : null;
        onChange(formattedDate);
        onBlur(formattedDate);
    };

    return (
        <DatePicker
            value={value ? dayjs(value) : null} // Use dayjs here
            onChange={handleChange}
            format="YYYY-MM-DD"
        />
    );
};

const StatusComponent = ({ value, onChange, onBlur, column, onUpdateColumn }) => {
    const [isEditingLabels, setIsEditingLabels] = useState(false);
    const [labels, setLabels] = useState(column.options || [
        { label: 'Not Started', color: '#c0c0c0' },
        { label: 'Working on it', color: '#1890ff' },
        { label: 'Stuck', color: '#f5222d' },
        { label: 'Done', color: '#52c41a' }
    ]);

    const handleMenuClick = ({ key }) => {
        if (key === 'edit') {
            handleEditLabels();
        } else {
            onChange(key);
            onBlur(key);
        }
    };

    const handleEditLabels = () => {
        setIsEditingLabels(true);
    };

    const handleLabelChange = (index, field, newValue) => {
        const newLabels = labels.map((label, i) => 
            i === index ? { ...label, [field]: newValue } : label
        );
        setLabels(newLabels);
    };

    const handleAddLabel = () => {
        if (labels.length < 20) {
            const newColor = preSpecifiedColors[labels.length % preSpecifiedColors.length];
            setLabels([...labels, { label: `Label ${labels.length + 1}`, color: newColor }]);
        }
    };

    const handleRemoveLabel = (index) => {
        const newLabels = labels.filter((_, i) => i !== index);
        setLabels(newLabels);
    };

    const handleSaveLabels = () => {
        onUpdateColumn(column.name, { options: labels });
        setIsEditingLabels(false);
    };

    const menuItems = [
        ...labels.map(label => ({
            key: label.label,
            label: (
                <Tag color={label.color} style={{ width: '100%' }}>
                    {label.label}
                </Tag>
            ),
        })),
        {
            key: 'edit',
            label: (
                <>
                    <EditOutlined /> Edit Labels
                </>
            ),
        },
    ];

    return (
        <>
            <Dropdown
                menu={{ items: menuItems, onClick: handleMenuClick }}
                trigger={['click']}
            >
                <Button style={{ width: '100%', height: '100%', padding: 0 }}>
                    {value ? (
                        <Tag color={labels.find(l => l.label === value)?.color} style={{ width: '100%', height: '100%', margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {value}
                        </Tag>
                    ) : (
                        'Select Status'
                    )}
                </Button>
            </Dropdown>
            <Modal
                title="Edit Labels"
                open={isEditingLabels}
                onOk={handleSaveLabels}
                onCancel={() => setIsEditingLabels(false)}
            >
                {labels.map((label, index) => (
                    <div key={index} style={{ marginBottom: 8 }}>
                        <Input
                            value={label.label}
                            onChange={(e) => handleLabelChange(index, 'label', e.target.value)}
                            style={{ width: 'calc(100% - 160px)' }}
                        />
                        <Select
                            value={label.color}
                            onChange={(color) => handleLabelChange(index, 'color', color)}
                            style={{ width: 120, marginLeft: 8 }}
                        >
                            {preSpecifiedColors.map((color) => (
                                <Option key={color} value={color}>
                                    <div style={{ backgroundColor: color, width: '100%', height: '20px' }}></div>
                                </Option>
                            ))}
                        </Select>
                        <Button onClick={() => handleRemoveLabel(index)} style={{ marginLeft: 8 }}>
                            Remove
                        </Button>
                    </div>
                ))}
                {labels.length < 20 && <Button onClick={handleAddLabel}>Add Label</Button>}
            </Modal>
        </>
    );
};

const DropdownComponent = ({ value, onChange, onBlur }) => {
    const handleChange = (newValue) => {
        onChange(newValue);
    };

    const handleBlur = () => {
        onBlur(value);
    };

    return (
        <Select
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
        >
            <Option value="option1">Option 1</Option>
            <Option value="option2">Option 2</Option>
            <Option value="option3">Option 3</Option>
        </Select>
    );
};

const CheckboxComponent = ({ value, onChange, onBlur }) => {
    const handleChange = (e) => {
        onChange(e.target.checked);
    };

    const handleBlur = () => {
        onBlur(value);
    };

    return (
        <Checkbox
            checked={value}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
};

const FilesComponent = ({ value, onChange, onBlur }) => {
    const handleChange = ({ fileList }) => {
        onChange(fileList);
    };

    const handleBlur = () => {
        onBlur(value);
    };

    return (
        <Upload
            fileList={value}
            onChange={handleChange}
            onBlur={handleBlur}
        >
            <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
    );
};

const LabelComponent = ({ value, onChange, onBlur }) => {
    // Ensure value is always an array
    const labels = Array.isArray(value) ? value : value ? [value] : [];

    const handleClose = (removedValue) => {
        const newValue = labels.filter((v) => v !== removedValue);
        onChange(newValue);
    };

    const handleBlur = () => {
        onBlur(labels);
    };

    return (
        <>
            {labels.map((v) => (
                <Tag
                    key={v}
                    closable
                    onClose={() => handleClose(v)}
                >
                    {v}
                </Tag>
            ))}
            <Input
                type="text"
                size="small"
                style={{ width: 78 }}
                value=""
                onChange={(e) => {
                    if (e.target.value && !labels.includes(e.target.value)) {
                        onChange([...labels, e.target.value]);
                    }
                }}
                onBlur={handleBlur}
            />
        </>
    );
};

const AssigneeComponent = ({ value, onChange, onBlur, users }) => {
    const handleChange = (selectedUsers) => {
        onChange(selectedUsers);
        onBlur(selectedUsers);
    };

    return (
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select assignees"
            onChange={handleChange}
            value={Array.isArray(value) ? value : []}
            onBlur={() => onBlur(value)}
            popupMatchSelectWidth={false}
        >
            {users.map(user => (
                <Select.Option key={user.id} value={user.id}>
                    {`${user.name} ${user.surname}`}
                </Select.Option>
            ))}
        </Select>
    );
};

const AssigneeFilterComponent = ({ value, onChange, users }) => {
    return (
        <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Filter assignees"
            onChange={onChange}
            value={value}
        >
            {users.map(user => (
                <Select.Option key={user.id} value={user.id}>
                    {`${user.name} ${user.surname}`}
                </Select.Option>
            ))}
        </Select>
    );
};

const AssigneeDisplayComponent = ({ value, users }) => {
    const assignees = value.map(userId => {
        const user = users.find(u => u.id === userId);
        return user ? `${user.name} ${user.surname}` : '';
    });

    return (
        <div>
            {assignees.map((assignee, index) => (
                <Tag key={index} color="blue">{assignee}</Tag>
            ))}
        </div>
    );
};

const ColumnTypeComponents = {
    text: TextComponent,
    numbers: NumbersComponent,
    date: DateComponent,
    status: StatusComponent,
    dropdown: DropdownComponent,
    checkbox: CheckboxComponent,
    files: FilesComponent,
    label: LabelComponent,
    assignee: AssigneeComponent,
    assigneeFilter: AssigneeFilterComponent,
    assigneeDisplay: AssigneeDisplayComponent,
};

export default ColumnTypeComponents;